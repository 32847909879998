import {
  FORGOT_PASSWORD_SEND_CODE,
  GET_OVERALL_AVERAGES_SUCCESS,
  GET_PAFDATA_GRAPH_SUCCESS,
  GET_USER_ALLERGENS_GRAPH_SUCCESS,
  GET_USER_DETAILS_GRAPHPAGE_SUCCESS,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_INDOOR_AIR_QUALITY_GRAPH_SUCCESS,
  GET_USER_OUTDOOR_AIR_QUALITY_GRAPH_SUCCESS,
  GET_USER_PROFILE_SUCCESS,
  LOGIN_SUCCESS,
  LOGOUT_DETAIL_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  UPDATE_USER_DETAILS_SUCCESS,
  THIS_WEEK_INHALER_COUNT_SUCCESS,
} from "../Actions/ActionTypes";

const iState = {
  loginData: {},
  userData: {},
  userDataDisplay: {},
  updatUserDataResponse: {},
  logoutDetails: {},
  forgotPassword: {},
  resetPasswordResponse: {},
  userOutdoorAirQualityGraphResponse: {},
  userIndoorAirQualityGraphResponse: {},
  userAllergensGraphResponse: {},
  pafDataGraphResponse: {},
  OverallAveragesResponse: {},
  userDetailsGraphpageResponse: {},
  thisWeekInhalerCountResponse: {},
};

export const authReducer = (state = iState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        loginData: action.payload,
      };
    case FORGOT_PASSWORD_SEND_CODE:
      return {
        ...state,
        forgotPassword: action.payload,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPassword: action.payload,
      };
    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userDataDisplay: action.payload,
      };
    case GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userData: action.payload,
      };
    case UPDATE_USER_DETAILS_SUCCESS:
      return {
        ...state,
        updatUserDataResponse: action.payload,
      };
    case GET_USER_OUTDOOR_AIR_QUALITY_GRAPH_SUCCESS:
      return {
        ...state,
        userOutdoorAirQualityGraphResponse: action.payload,
      };
    case GET_USER_INDOOR_AIR_QUALITY_GRAPH_SUCCESS:
      return {
        ...state,
        userIndoorAirQualityGraphResponse: action.payload,
      };
    case GET_USER_ALLERGENS_GRAPH_SUCCESS:
      return {
        ...state,
        userAllergensGraphResponse: action.payload,
      };
    case GET_PAFDATA_GRAPH_SUCCESS:
      return {
        ...state,
        pafDataGraphResponse: action.payload,
      };
    case GET_OVERALL_AVERAGES_SUCCESS:
      return {
        ...state,
        OverallAveragesResponse: action.payload,
      };

    case GET_USER_DETAILS_GRAPHPAGE_SUCCESS:
      return {
        ...state,
        userDetailsGraphpageResponse: action.payload,
      };
    case LOGOUT_DETAIL_SUCCESS:
      return {
        ...state,
        logoutDetails: action.payload,
      };
    case THIS_WEEK_INHALER_COUNT_SUCCESS:
        return {
          ...state,
          thisWeekInhalerCountResponse: action.payload,
        };  
    default:
      return state;
  }
};
