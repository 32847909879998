//========== Loading Action ==========
export const LOADING = "LOADING";

// ========= Auth Action =============
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_NEW_SUCCESS = "GET_USER_PROFILE_NEW_SUCCESS";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_NEW_SUCCESS =
  "UPDATE_USER_PROFILE_NEW_SUCCESS";
export const FORGOT_PASSWORD_SEND_CODE = "FORGOT_PASSWORD_SEND_CODE";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const LOGOUT_DETAIL_SUCCESS = "LOGOUT_DETAIL_SUCCESS";
export const GET_USER_ALLERGENS_GRAPH_SUCCESS =
  "GET_USER_ALLERGENS_GRAPH_SUCCESS";
export const GET_USER_OUTDOOR_AIR_QUALITY_GRAPH_SUCCESS =
  "GET_USER_OUTDOOR_AIR_QUALITY_GRAPH_SUCCESS";
export const GET_USER_INDOOR_AIR_QUALITY_GRAPH_SUCCESS =
  "GET_USER_INDOOR_AIR_QUALITY_GRAPH_SUCCESS";
export const GET_PEAKAIRDATA_GRAPH_SUCCESS = "GET_PEAKAIRDATA_GRAPH_SUCCESS";
export const GET_INHELAR_WEEKLY_DATA_GRAPH_SUCCESS =
  "GET_INHELAR_WEEKLY_DATA_GRAPH_SUCCESS";
export const GET_INHELAR_TOTAL_WEEK_DATA_GRAPH_SUCCESS =
  "GET_INHELAR_TOTAL_WEEK_DATA_GRAPH_SUCCESS";
export const GET_PAFDATA_GRAPH_SUCCESS = "GET_PAFDATA_GRAPH_SUCCESS";
export const GET_PAFDATA_GRAPH_NEW_SUCCESS = "GET_PAFDATA_GRAPH_NEW_SUCCESS";
export const GET_OVERALL_AVERAGES_SUCCESS = "GET_OVERALL_AVERAGES_SUCCESS";
export const GET_TODAY_OVERALL_AVERAGES_SUCCESS =
  "GET_TODAY_OVERALL_AVERAGES_SUCCESS";
export const GET_USER_DETAILS_GRAPHPAGE_SUCCESS =
  "GET_USER_DETAILS_GRAPHPAGE_SUCCESS";
export const FORGOT_PASSWORD_LINK_VALIDATE = "FORGOT_PASSWORD_LINK_VALIDATE";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const THIS_WEEK_INHALER_COUNT_SUCCESS = "THIS_WEEK_INHALER_COUNT_SUCCESS";
export const GET_PRIVATE_POLICY = "GET_PRIVATE_POLICY";
export const SET_TERMS = "SET_TERMS";
export const GET_TERMS = "GET_TERMS";
