import React,{ useEffect, useState } from "react";
import {
  Container,
  Col,
  Row,
} from "react-bootstrap";
import parse from 'html-react-parser';
import { useDispatch } from "react-redux";
import { getPrivatePolicy } from "../../Redux/Actions";
// import './style.css';

export const PrivacyPolicyData = () => {
  const [addData, setAddData] = useState('')
  const dispatch = useDispatch();
  useEffect(() => {
    document.title = 'MOV || PRIVACY POLICY'
  },[])
  useEffect(async () => {
    const response =  await dispatch(getPrivatePolicy())
    if(response?.type=='GET_PRIVATE_POLICY')
    {
        setAddData(response?.payload)
    }
  }, []);

  return (
    <>
      <div>
        <Container className='px-md-5'>

          <Row>
            <Col lg={12}>
              <h2 className="text-center mt-5 my-5 login_form_title2">MOV Web & Mobile Privacy Policy</h2>
              <div className="search-bar">
               {parse(addData)}
              </div>
        </Col>
    </Row>
    </Container>
    </div>
    </>
  );
};
