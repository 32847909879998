import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import "./resetpassword.css";
import mov_logo from "../../Assets/images/Mov_logo1.png";
import key_logo from "../../Assets/images/Vpn_Key_Icon_1.png";
import { resetPasswordAction } from "../../Redux/Actions";
import swal from "sweetalert";
import { getItemLocalStorage } from "../../Utils/Util";

export const ResetPassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    userId: getItemLocalStorage("userId"),
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [error, setError] = useState("");
  const [error1, setError1] = useState("");
  const confirmInput = useRef(null);
  useEffect(() => {
    document.title = 'MOV || RESET PASSWORD'
  },[])
  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setValues({
      ...values,
      [name]: value,
    });
  };

  // ======= submit Reset Password request ==============
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      const response = await dispatch(resetPasswordAction(values));
      const resetPasswordSuccess = response?.payload;
      if (resetPasswordSuccess) {
        if (resetPasswordSuccess?.status == 200) {
          swal({
            title: "Success!",
            text: resetPasswordSuccess?.data?.message,
            buttons: false,
            icon: "success",
            timer: 2000,
          });
          history.push("/dashboard");
        } else {
          swal({
            title: "Error!",
            text: resetPasswordSuccess?.data?.message,
            buttons: false,
            icon: "error",
            timer: 2000,
          });
        }
      }
    } catch (err) {
      swal({
        title: "Current Password does not match",
        text: err?.message,
        buttons: false,
        icon: "error",
        timer: 2000,
      });
    }
  };
  const handleOnBlur = (e) => {
    if (values.newPassword !== values.confirmPassword) {
      setError("Passwords do not match");
      confirmInput.current.focus();
    } else {
      setError("");
    }
  };
  return (
    <div className="login-contaoner">
      <div className="login_wrapper">
        <form className="login_form" onSubmit={handleSubmit}>
          <div className="logo_img">
            <img src={mov_logo} alt="logo" />
            <p>Reset your password</p>
          </div>
          <div className="form-group">
            <label for="exampleInputPassword1">Current Password</label>
            <input
              type="password"
              className="form-control"
              id="exampleInputPassword1"
              name="currentPassword"
              required
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label for="exampleInputPassword1">New Password</label>
            <input
              type="password"
              className="form-control"
              id="exampleInputPassword1"
              name="newPassword"
              required
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label for="exampleInputPassword1">Confirm New Password</label>
            <div className="input_icon">
              <input
                type="password"
                className="form-control"
                id="exampleInputPassword2"
                name="confirmPassword"
                required
                ref={confirmInput}
                onBlur={handleOnBlur}
                onChange={handleInputChange}
              />
            </div>
            {error && <span className="error-text">{error}</span>}
          </div>
          <button type="submit" className="btn btn-submit">
            Reset Password
          </button>
        </form>
      </div>
    </div>
  );
};
