export const SIGN_IN = "/";
export const DASHBOARD = "/home";
export const MYDATA = "/my-data";
export const MYASTHAMADATA = "/my-asthamadata";
export const MYACCOUNT = "/my-account";
export const FORGOT_PASSWORD = "/forgot-password";
export const RESET_PASSWORD = "/reset-password";
export const CHANGE_PASSWORD = "/change-password";
export const PRIVACY_PRACTICE = '/privacy_practices_page'
export const TERMS_OF_USE = '/terms_of_use_page'
export const PRIVACY_POLICY_PAGE = '/privacy_policy_page'
export const LearningToolkit = '/learning_toolkit'
