import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import "./resetpassword.css";
import mov_logo from "../../Assets/images/Mov_logo1.png";
import key_logo from "../../Assets/images/Vpn_Key_Icon_1.png";
import { resetPasswordAction } from "../../Redux/Actions";
import swal from "sweetalert";
import { getItemLocalStorage } from "../../Utils/Util";

export const ChangePassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    userId: getItemLocalStorage("userId"),
    newPassword: "",
    confirmPassword: "",
  });
  const [error, setError] = useState("");
  const confirmInput = useRef(null);

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setValues({
      ...values,
      [name]: value,
    });
  };

  // ======= submit Reset Password request ==============
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleOnBlur = (e) => {
    if (values.newPassword !== values.confirmPassword) {
      setError("Passwords do not match");
      confirmInput.current.focus();
    } else {
      setError("");
    }
  };

  return (
    <div className="login-contaoner">
      <div className="login_wrapper">
        <form className="login_form" onSubmit={handleSubmit}>
          <div className="logo_img">
            <img src={mov_logo} alt="logo" />
            <p>Update your password</p>
          </div>
          <div className="form-group">
            <label for="exampleInputPassword1">New Password</label>
            <div className="input_icon">
              <i>
                <img src={key_logo} alt="key-icon" />
              </i>
              <input
                type="password"
                className="form-control"
                id="exampleInputPassword1"
                name="newPassword"
                required
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="form-group">
            <label for="exampleInputPassword1">Confirm New Password</label>
            <div className="input_icon">
              <i>
                <img src={key_logo} alt="key-icon" />
              </i>
              <input
                type="password"
                className="form-control"
                id="exampleInputPassword2"
                name="confirmPassword"
                required
                ref={confirmInput}
                onBlur={handleOnBlur}
                onChange={handleInputChange}
              />
            </div>
            {error && <span className="error-text">{error}</span>}
          </div>
          <button type="submit" className="btn btn-submit">
            Change Password
          </button>
        </form>
      </div>
    </div>
  );
};
