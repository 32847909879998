import { combineReducers } from "redux";
import {authReducer} from "./AuthReducer";
import {loadingReducer} from "./LoadingReducer"
import { LOGOUT_DETAIL_SUCCESS } from "../Actions";

const appReducer  = combineReducers({
    authReducer,
    loadingReducer,
})

const rootReducer = (state, action) => {
    if (action.type === LOGOUT_DETAIL_SUCCESS) {
      state = undefined;
    }
    return appReducer(state, action)
  }

export default rootReducer;