import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  ForgotPassword,
  Login,
  ResetPassword,
  Home,
  ChangePassword,
} from "../Components";
import MyAsthma from "../Components/Dashboard/my-asthama/MyAsthama";
import UpdateForgotPassword from "../Components/Auth/UpdateForgotPaasword";
import { PrivateRoute } from "./PrivateRoute";
import { getItemLocalStorage } from "../Utils/Util";
import PrivayPractices from "../Components/pages/PrivacyPractices"
import {
  CHANGE_PASSWORD,
  DASHBOARD,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  MYASTHAMADATA,
  SIGN_IN,
  MYDATA,
  MYACCOUNT,
  PRIVACY_PRACTICE,
  TERMS_OF_USE,
  PRIVACY_POLICY_PAGE,
  LearningToolkit
} from "./RoutesConstant";
import { OpenRoute } from "./OpenRoute";
import Dashboard from "../Components/pages/Dashboard";
import MyDataPage from "../Components/pages/MyDataPage";
import MyAsthmaPage from "../Components/pages/MyAsthmaPage";
import Account from "../Components/pages/Account";
import { TermsAndConditionData } from "../Components/pages/termsAndConditionData";
import { PrivacyPolicyData } from "../Components/pages/PrivacyPolicyData";
import { LearningToolKit } from "../Components/pages/learningToolkit";

export const Routes = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    getItemLocalStorage("accessToken") ? true : false
  );
  const userLogin = useSelector((state) => {
    return state.authReducer;
  });

  useEffect(() => {
    const existingAuth = getItemLocalStorage("accessToken");
    if (existingAuth) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [userLogin]);

  return (
    <BrowserRouter>
      <Switch>
        <OpenRoute
          exact
          path={SIGN_IN}
          component={Login}
          authTokens={isAuthenticated}
        />
        <OpenRoute
          exact
          path={"/change-password/:id"}
          component={UpdateForgotPassword}
        />
        <OpenRoute
          exact
          path={FORGOT_PASSWORD}
          component={ForgotPassword}
          // authTokens={isAuthenticated}
        />
          <Route
            exact
            path={PRIVACY_PRACTICE}
            component={PrivayPractices}
          // authTokens={isAuthenticated}
          />
          <Route
            exact
            path={TERMS_OF_USE}
            component={TermsAndConditionData}
          // authTokens={isAuthenticated}
          />
          <Route
            exact
            path={PRIVACY_POLICY_PAGE}
            component={PrivacyPolicyData}
          // authTokens={isAuthenticated}
          />
          <Route
            exact
            path={LearningToolkit}
            component={LearningToolKit}
          // authTokens={isAuthenticated}
          />
        <PrivateRoute
          exact
          path={RESET_PASSWORD}
          component={ResetPassword}
          authTokens={isAuthenticated}
        />
        <PrivateRoute
          exact
          path={CHANGE_PASSWORD}
          component={ChangePassword}
          authTokens={isAuthenticated}
        />
        <PrivateRoute
          exact
          path={DASHBOARD}
          component={Dashboard}
          authTokens={isAuthenticated}
        />
        <PrivateRoute
          exact
          path={MYDATA}
          component={MyDataPage}
          authTokens={isAuthenticated}
        />
        <PrivateRoute
          exact
          path={MYASTHAMADATA}
          component={MyAsthmaPage}
          authTokens={isAuthenticated}
        />
        <PrivateRoute
          exact
          path={MYACCOUNT}
          component={Account}
          authTokens={isAuthenticated}
        />
      </Switch>
    </BrowserRouter>
  );
};
