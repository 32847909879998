import React from "react";
import "../Dashboard/home.css";
import "../Dashboard/style.css";
import {
  TabContainer,
  TabPane,
  TabContent,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
} from "react-bootstrap";
import SideBar from "../Common/SideBar";
import { TopBar } from "../Common/TopBar";
import DashboardView from "../Dashboard/dashboard-view/DashboardView";
const Dashboard = () => {
  return (
    <>
      <TopBar />
      <TabContainer
        id="left-tabs-example"
        defaultActiveKey="first"
        className="container-fluid"
      >
        <Row className="home-tabs">
          <Col xl={3} className="sidebar">
            <SideBar />
          </Col>

          <Col xl={12} className="right-bar">
            <TabContent>
              <TabPane eventKey="first">
                <DashboardView />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </TabContainer>
    </>
  );
};

export default Dashboard;
