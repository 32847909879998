import React from "react";
import "./myAsthama.css";

import image_placeholder_1 from "../../../Assets/images/dashboard/Image_placeholder_1.png";
import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import copyright from "../../../Assets/images/dashboard/copyright.png";
import { useDispatch } from "react-redux";
import {
  getUserDetailAction,
  getUserProfileAction,
  updateUserDetailsAction,
  loadingAction,
  uploadProfileAction,
} from "../../../Redux/Actions";
import swal from "sweetalert";
import { getItemLocalStorage } from "../../../Utils/Util";
import { profileUploadURL } from "../../../Services/Config";
import moment from "moment";
import { Button } from "react-bootstrap";

const MyAsthama = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState("");
  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);
  const [userProfileData, setUserProfileData] = useState({});

  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  

  const [allergisesListArr, setAllergisesListArr] = useState([
    { label: "Pollen", name: "pollen", isAdded: false },
    { label: "Trees", name: "trees", isAdded: false },
    { label: "Ragweed", name: "ragweed", isAdded: false },
    { label: "Oak", name: "oak", isAdded: false },
    { label: "Cats", name: "cats", isAdded: false },
    { label: "Dogs", name: "dogs", isAdded: false },
    { label: "Pet Dander", name: "Pet_Dander", isAdded: false },
    { label: "Cleaning Products", name: "cleaning_Products", isAdded: false },
    { label: "Mold", name: "mold", isAdded: false },
    { label: "Peanuts", name: "peanuts", isAdded: false },
    { label: "Shellfish", name: "shellfish", isAdded: false },
    { label: "Bees", name: "bees", isAdded: false },
  ]);
  const [medicationsListArr, setMedicationsListArr] = useState([
    { label: "ProAir HFA", name: "proAir_HFA", isAdded: false },
    { label: "Proventil HFA", name: "proventil_HFA", isAdded: false },
    { label: "Ventolin HFA", name: "Ventolin_HFA", isAdded: false },
    {
      label: "Generic Albuterol HFA",
      name: "Generic_Albuterol_HFA",
      isAdded: false,
    },
    { label: "Symbicort", name: "symbicort", isAdded: false },
    { label: "Advair", name: "advair", isAdded: false },
    { label: "Breo", name: "breo", isAdded: false },
    { label: "Flovent", name: "flovent", isAdded: false },
    { label: "Singulair", name: "singulair", isAdded: false },

    { label: "Allergy Shots", name: "allergy shots", isAdded: false },
  ]);
  const [editValues, setEditValues] = useState({
    userId: getItemLocalStorage("userId"),
    allergies: JSON.stringify(allergisesListArr),
    extraAllergiesNames: "",
    medicinations: JSON.stringify(medicationsListArr),
    extraMedicinationsNames: "",
    petsLiving: "",
    emergencyRoom: "",
    primaryCareProvider: "",
    resident: "",
    ethnicity: "",
  });
  useEffect(() => {
    document.title = 'MOV || MY ASTHMA'
  },[])
  useEffect(() => {
    // getUserProfile();
    getUserDetails();
  }, []);

  // const getUserProfile = async () => {
  //   try {
  //     const response = await dispatch(
  //       getUserProfileAction({ userId: getItemLocalStorage("userId") })
  //     );
  //     const userProfileSuccess = response?.payload;
  //     if (userProfileSuccess) {
  //       if (userProfileSuccess?.status === 200) {
  //         setUserProfileData(userProfileSuccess?.data?.data);
  //         setEditValues({
  //           userId: getItemLocalStorage("userId"),
  //           allergies: (userProfileSuccess?.data?.data?.allergies ? userProfileSuccess?.data?.data?.allergies : JSON.stringify(allergisesListArr)),
  //           extraAllergiesNames: userProfileSuccess?.data?.data?.extraAllergiesNames,
  //           medicinations: (userProfileSuccess?.data?.data?.medicinations? userProfileSuccess?.data?.data?.medicination0s : JSON.stringify(medicationsListArr)),
  //           extraMedicinationsNames: userProfileSuccess?.data?.data?.extraMedicinationsNames,
  //           petsLiving: userProfileSuccess?.data?.data?.petsLiving,
  //           emergencyRoom: userProfileSuccess?.data?.data?.emergencyRoom,
  //           primaryCareProvider: userProfileSuccess?.data?.data?.primaryCareProvider,
  //           resident: userProfileSuccess?.data?.data?.resident,
  //           ethnicity: userProfileSuccess?.data?.data?.ethnicity,
  //         });
          // setEditValues(userProfileSuccess?.data?.data);

  //       } else {
  //         swal({
  //           title: "Error!",
  //           text: userProfileSuccess?.data?.message,
  //           buttons: false,
  //           icon: "error",
  //           timer: 2000,
  //         });
  //       }
  //     }
  //   } catch (err) {
  //     swal({
  //       title: "Error",
  //       text: err?.message,
  //       buttons: false,
  //       icon: "error",
  //       timer: 2000,
  //     });
  //   }
  // };
  const getUserDetails = async () => {
    try {
      const response = await dispatch(
        getUserDetailAction({ userId: getItemLocalStorage("userId") })
      );
      const userDetailSuccess = response?.payload;
      if (userDetailSuccess) {
        if (userDetailSuccess?.status == 200) {
          if (JSON.parse(userDetailSuccess?.data?.data?.allergies).length > 0) {
            setAllergisesListArr(
              JSON.parse(userDetailSuccess?.data?.data?.allergies)
            );
            setMedicationsListArr(
              JSON.parse(userDetailSuccess?.data?.data?.medicinations)
            );
          }
          // setEditValues(userDetailSuccess?.data?.data);
          setEditValues({
                      userId: getItemLocalStorage("userId"),
                      allergies: (userDetailSuccess?.data?.data?.allergies),
                      extraAllergiesNames: userDetailSuccess?.data?.data?.extraAllergiesNames,
                      medicinations: (userDetailSuccess?.data?.data?.medicinations),
                      extraMedicinationsNames: userDetailSuccess?.data?.data?.extraMedicinationsNames,
                      petsLiving: userDetailSuccess?.data?.data?.petsLiving,
                      emergencyRoom: userDetailSuccess?.data?.data?.emergencyRoom,
                      primaryCareProvider: userDetailSuccess?.data?.data?.primaryCareProvider,
                      resident: userDetailSuccess?.data?.data?.resident,
                      ethnicity: userDetailSuccess?.data?.data?.ethnicity,
                    });
        } else {
          swal({
            title: "Error!",
            text: userDetailSuccess?.data?.message,
            buttons: false,
            icon: "error",
            timer: 2000,
          });
        }
      }
    } catch (err) {
      swal({
        title: "Error",
        text: err?.message,
        buttons: false,
        icon: "error",
        timer: 2000,
      });
    }
  };

  const updateUserProfile = async (data) => {
    try {
      const response = await dispatch(updateUserDetailsAction(data));
      const updateProfileSuccess = response?.payload;
      if (updateProfileSuccess) {
        if (updateProfileSuccess?.status === 200) {
          getUserDetails();
          // getUserProfile();
          handleHide();
          swal({
            title: "Success!",
            text: updateProfileSuccess?.data?.message,
            buttons: false,
            icon: "success",
            timer: 2000,
          });
        } else {
          swal({
            title: "Error!",
            text: updateProfileSuccess?.data?.message,
            buttons: false,
            icon: "error",
            timer: 2000,
          });
        }
      }
    } catch (err) {
      swal({
        title: "Error",
        text: err?.message,
        buttons: false,
        icon: "error",
        timer: 2000,
      });
    }
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleShowModal()
    // updateUserProfile(editValues);
  };

  const handleConfirm = ()=>{
    updateUserProfile(editValues);
    handleClose()

  }

  const medicationsOtherChange = (e) => {
    const value = e.target.value;
    setEditValues({
      ...editValues,
      extraMedicinationsNames: value,
    });
  };
  const allergisesOtherChange = (e) => {
    const value = e.target.value;
    setEditValues({
      ...editValues,
      extraAllergiesNames: value,
    });
  };

  const handleSelectOption = (e) => {
    const value = e.target.value;
    setEditValues({
      ...editValues,
      ethnicity: value,
    });
  };

  const handleRadio = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setEditValues({
      ...editValues,
      [name]: value,
    });
  };

  const changeAllergisesList = (e, item) => {
    let a = allergisesListArr.map((x) =>
      x.name === item.name ? { ...x, isAdded: e.target.checked } : x
    );
    setAllergisesListArr(a);
    setEditValues({
      ...editValues,
      allergies: JSON.stringify(a),
    });
  };

  const changeMedicinationsList = (e, item) => {
    let a = medicationsListArr.map((x) =>
      x.name === item.name ? { ...x, isAdded: e.target.checked } : x
    );

    setMedicationsListArr(a);
    setEditValues({
      ...editValues,
      medicinations: JSON.stringify(a),
    });
  };

  const onFileChange = async (e) => {
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("file", e.target.files[0]);
    formData.append("userId", getItemLocalStorage("userId"));

    // Details of the uploaded file

    // Request made to the backend api
    // Send formData object
    try {
      const response = await dispatch(uploadProfileAction(formData));
      const uploadProfileSuccess = response?.payload;
      if (uploadProfileSuccess) {
        if (uploadProfileSuccess?.status == 200) {
          // getUserProfile();
        } else {
          swal({
            title: "Error!",
            text: uploadProfileSuccess?.data?.message,
            buttons: false,
            icon: "error",
            timer: 2000,
          });
        }
      }
    } catch (err) {
      swal({
        title: "Error",
        text: err?.message,
        buttons: false,
        icon: "error",
        timer: 2000,
      });
    }
  };
  const refreshPage = () => {
    window.location.reload();
  };
  return (
    <>
      <Modal show={showModal} onHide={handleClose} centered>
        {/* <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Save Changes</Modal.Title>
        </Modal.Header> */}
        <Modal.Body className="p-4">Are you sure, you want to save the changes?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="my-asthama">
        <div className="my-asthama-title">Asthma Details</div>
        <div className="my-asthama-desc">
          Here you can keep track of your current Asthma experience. These
          details may be things you have discussed with your healthcare provider
          as well as your social determinants. These will help us to provide you
          with a more personalized experience. Remember, you own all of your
          data. If you want to download a copy to share with your provider or
          loved ones, or just for your own records, click the 'Download' Icon
          and a PDF of the data will be created.
        </div>
        <form onSubmit={handleEditSubmit}>
          <div className="amodal-body">
            <h5 className="amodal-body-h">
              Do you have any allergies?{" "}
              <span className="amodal-body-span">(Select all that apply)</span>
            </h5>
            <div className="allergies_checkboxes">
              {allergisesListArr.length > 0 &&
                allergisesListArr?.map((item, idx) => {
                  return (
                      <div className="custom-control custom-checkbox" key={ idx }>
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          style={{ border: "1px solid #A2A2A2" }}
                          checked={item.isAdded}
                          name={item.name}
                          id={item.name}
                          onChange={(e) => changeAllergisesList(e, item)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={item.name}
                        >
                          {item.label}
                        </label>
                      </div>
                  );
                })}

              <input
                type="text"
                placeholder="List any others"
                className="list_input"
                value={editValues.extraAllergiesNames}
                onChange={allergisesOtherChange}
              />
            </div>
            <h5 className="amodal-body-h">
              Are you currently taking any of these Asthma medications?{" "}
              <span className="amodal-body-span">(Select all that apply)</span>
            </h5>
            <div className="allergies_checkboxes">
              {medicationsListArr?.length > 0 &&
                medicationsListArr?.map((item, idx) => {
                  return (
                      <div className="custom-control custom-checkbox" key = { idx }>
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          checked={item.isAdded}
                          name={item.name}
                          id={item.name}
                          onChange={(e) => changeMedicinationsList(e, item)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={item.name}
                        >
                          {item.label}
                        </label>
                      </div>
                  );
                })}
            </div>
            <div className="cm-radios">
              <h5 className="mr-5">Do you have Pets living with you? </h5>
              <div className="d-flex radio-btn-sec">
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    id="customRadio1l"
                    name="petsLiving"
                    className="custom-control-input"
                    value="1"
                    checked={editValues?.petsLiving == '1'}
                    onChange={handleRadio}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customRadio1l"
                  >
                    Yes
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    id="customRadio2l"
                    name="petsLiving"
                    className="custom-control-input"
                    value="0"
                    checked={editValues?.petsLiving == '0' }
                    onChange={handleRadio}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customRadio2l"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
            <div className="cm-radios">
              <h5 className="mr-5">
                Have you been to the Emergency Room for your Asthma in the last
                year?{" "}
              </h5>
              <div className="d-flex radio-btn-sec">
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    id="customRadio1e"
                    name="emergencyRoom"
                    className="custom-control-input"
                    value="1"
                    checked={editValues.emergencyRoom == "1"}
                    onChange={handleRadio}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customRadio1e"
                  >
                    Yes
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    id="customRadio2e"
                    name="emergencyRoom"
                    className="custom-control-input"
                    value="0"
                    checked={editValues.emergencyRoom == "0"}
                    onChange={handleRadio}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customRadio2e"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
            <div className="cm-radios">
              <h5 className="mr-5">Do you have a Primary Care Provider? </h5>
              <div className="d-flex radio-btn-sec">
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    id="customRadio1p"
                    name="primaryCareProvider"
                    className="custom-control-input"
                    value="1"
                    checked={editValues.primaryCareProvider == "1"}
                    onChange={handleRadio}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customRadio1p"
                  >
                    Yes
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    id="customRadio2p"
                    name="primaryCareProvider"
                    className="custom-control-input"
                    value="0"
                    checked={editValues.primaryCareProvider == "0"}
                    onChange={handleRadio}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customRadio2p"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
            <div className="cm-radios">
              <h5 className="mr-5">Do you rent or own your residence? </h5>
              <div className="d-flex radio-btn-sec">
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    id="customRadio1r"
                    name="resident"
                    className="custom-control-input"
                    value="1"
                    checked={editValues.resident == "1"}
                    onChange={handleRadio}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customRadio1r"
                  >
                    Rent
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    id="customRadio2r"
                    name="resident"
                    className="custom-control-input"
                    value="0"
                    checked={editValues.resident == "0"}
                    onChange={handleRadio}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customRadio2r"
                  >
                    Own
                  </label>
                </div>
              </div>
            </div>
            <div className="cm-ethnicity">
              <h5 className="mt-0 mb-0 mr-5">What is your ethnicity? </h5>
              <select
                className="form-select"
                aria-label="Default select example"
                value={editValues.ethnicity}
                onChange={handleSelectOption}
              >
                <option defaultValue={0}>Select</option>
                <option value={1}>American Indian or Alaska Native</option>
                <option value={2}>Asian</option>
                <option value={3}>Black or African American</option>
                <option value={4}>Hispanic or Latino</option>
                <option value={5}>
                  Native Hawaiian or Other Pacific Islander
                </option>
                <option value={6}>White</option>
                <option value={6}>Other</option>
              </select>
            </div>
            <div className="cm-btns">
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={refreshPage}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-primary">
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default MyAsthama;
