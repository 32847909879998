import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import { forgotPasswordAction } from "../../Redux/Actions";
import "./ForgotPassword.css";
import email_logo from "../../Assets/images/Email_Icon_1.png";
import mov_logo from "../../Assets/images/Mov_logo1.png";

export const ForgotPassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [values, setValues] = useState({ email: "" });

  const handleInputChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setValues({
      ...values,
      [name]: value,
    });
  };
  useEffect(() => {
    document.title = 'MOV || FORGOT PASSWORD'
  },[])
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      const response = await dispatch(
        forgotPasswordAction({ email: values.email, type: "user" })
      );
      const sendCode = response?.payload;
      if (sendCode) {
        if (sendCode?.status == 200) {
          swal({
            title: sendCode?.data?.status ? "Success" : "Error!",
            text: sendCode?.data?.message,
            buttons: false,
            icon: sendCode?.data?.status ? "success" : "error",
            timer: 2000,
          });
        } else {
          swal({
            title: "Error!",
            text: sendCode?.data?.message,
            buttons: false,
            icon: "error",
            timer: 2000,
          });
        }
      }
    } catch (err) {
      swal({
        title: "Error",
        text: err?.message,
        buttons: false,
        icon: "error",
        timer: 2000,
      });
    }
  };

  return (
    <div className="forgot-password">
      <div className="login_wrapper">
        <form className="login_form" onSubmit={handleSubmit}>
          <div className="logo_img">
            <img src={mov_logo} alt="logo" />
            <p>Forgot Password</p>
          </div>
          <div className="form-group">
            <label for="exampleInputEmail1">Email</label>
         
              <input
                type="email"
                className="form-control"
                placeholder="Enter email"
                name="email"
                required
                pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                onChange={handleInputChange}
              />
           
          </div>

          <button type="submit" className="btn btn-submit">
            Send Link
          </button>
        </form>
      </div>
    </div>
  );
};
