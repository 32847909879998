import axios from "axios";
import { baseURL } from "./BaseApi";

export const getHeaders = () => {
  const apiKey = "mv52ea2d-4567-4956-9d19-35a7e75a2c17";
  const platform = "web";
  const accessToken = localStorage.getItem("accessToken");
  return {
    headers: {
      authorization: `Bearer ${accessToken}`,
      Accept: "application/json",
      "x-api-key": apiKey,
      platform: platform,
    },
  };
};

const getLoginHeaders = () => {
  const apiKey = "mv52ea2d-4567-4956-9d19-35a7e75a2c17";
  const platform = "web";
  return {
    headers: {
      Accept: "application/json",
      "x-api-key": apiKey,
      platform: platform,
    },
  };
};

export const AuthService = {
  login: (data) => {
    return axios.post(baseURL + `/api/v1/user/login`, data, getLoginHeaders());
  },
  getUserDetail: (data) => {
    return axios.post(
      baseURL + `/api/v1/user/user_details`,
      data,
      getHeaders()
    );
  },
  getUserProfileDetail: (data) => {
    return axios.post(
      baseURL + `/api/v1/user/user_profileDetails`,
      data,
      getHeaders()
    );
  },
  updateUserDetail: (data) => {
    return axios.post(
      baseURL + "/api/v1/user/update_user_details",
      data,
      getHeaders()
    );
  },

  // create by meee

  updateUserProfileNew: (data) => {
    return axios.post(
      baseURL + "/api/v1/user/update_user_profile",
      data,
      getHeaders()
    );
  },
  getUserProfileNew: (data) => {
    return axios.post(
      baseURL + "/api/v1/user/user_profile_info",
      data,
      getHeaders()
    );
  },

  logout: () => {
    return axios.get(baseURL + "/api/logout", getHeaders());
  },
  forgotPassword: (data) => {
    return axios.post(
      baseURL + "/api/v1/auth/userForgotEmailSend",
      data,
      getHeaders()
    );
  },
  resetPassword: (data) => {
    return axios.post(
      baseURL + "/api/v1/user/reset_user_password",
      data,
      getHeaders()
    );
  },
  updatePassword: (data) => {
    return axios.post(
      baseURL + "/api/v1/user/update_password",
      data,
      getHeaders()
    );
  },
  getUserOutdoorAirQualityGraph: (data) => {
    console.log("data--", data);
    return axios.post(
      // baseURL + `/api/v1/user/user_outdoorAirQuality_graph`,
      baseURL + `/api/v1/user/outdoor_air_quality_graph_user`,
      data,
      getHeaders()
    );
  },
  getUserIndoorAirQualityGraph: (data) => {
    return axios.post(
      // baseURL + `/api/v1/user/user_inDoorAirQuality_graph`,
      baseURL + `/api/v1/user/indoor_air_quality_graph_user`,
      data,
      getHeaders()
    );
  },
  getUserAllergensGraph: (data) => {
    return axios.post(
      // baseURL + `/api/v1/user/user_allergens_graph`,
      baseURL + `/api/v1/user/allergens_graph_user`,
      data,
      getHeaders()
    );
  },
  getPeakAirDataGraph: (data) => {
    return axios.post(
      baseURL + `/api/v1/user/paf_average_counts`,
      data,
      getHeaders()
    );
  },
  getInhelarWeeklyDataGraph: (data) => {
    return axios.post(
      baseURL + `/api/v1/user/inhelar_count_weekly`,
      data,
      getHeaders()
    );
  },
  getInhelarTotalWeekDataGraph: (data) => {
    return axios.post(
      baseURL + `/api/v1/user/inhaler_total_current_week_uses`,
      data,
      getHeaders()
    );
  },
  getPafDataGraph: (data) => {
    return axios.post(
      baseURL + `/api/v1/user/get_paf_levels_graph`,
      data,
      getHeaders()
    );
  },
  getPafDataGraphNew: (data) => {
    return axios.post(
      baseURL + `/api/v1/user/paf_value_graph_user`,
      data,
      getHeaders()
    );
  },
  getReliefInhalerGraph: (data) => {
    return axios.post(
      baseURL + `/api/v1/user/relief_inhaler_uses`,

      data,
      getHeaders()
    );
  },
  getOverallAverages: (data) => {
    return axios.post(
      baseURL + `/api/v1/user/overallAverages`,
      data,
      getHeaders()
    );
  },
  getTodayOverallAverages: (data) => {
    return axios.post(
      baseURL + `/api/v1/user/todays_averages`,
      data,
      getHeaders()
    );
  },
  getUserDetailsGraphpage: (data) => {
    return axios.post(
      baseURL + `/api/v1/user/user_profile_info`,
      data,
      getHeaders()
    );
  },
  uploadProfile: (data) => {
    return axios.post(
      baseURL + `/api/v1/user/uploadProfile`,
      data,
      getHeaders()
    );
  },
  forgotPasswordLinkValidate: (data) => {
    return axios.post(
      baseURL + `/api/v1/auth/forgotKeyValidate`,
      data,
      getHeaders()
    );
  },
  thisWeekInhalerCount: (data) => {
    return axios.post(
      baseURL + `/api/v1/user/this_week_inhaler_count`,
      data,
      getHeaders()
    );
  },
  getTERMS_OF_USE: () => {
    return axios.get(baseURL + "/api/v1/cms/terms_of_use", getHeaders());
  },
  getPrivatePolicy: () => {
    return axios.get(baseURL + "/api/v1/cms/privacy_policy", getHeaders());
  },
};

