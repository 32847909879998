import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import swal from "sweetalert";
import { loginAction } from "../../Redux/Actions";
import { setItemLocalStorage } from "../../Utils/Util";
import "./Login.css";
import mov_logo from "../../Assets/images/Mov_logo1.png";
import copyright from "../../Assets/images/dashboard/copyright.png";
import key_logo from "../../Assets/images/Vpn_Key_Icon_1.png";
import { Link } from "react-router-dom";
import {
  PRIVACY_PRACTICE,
  TERMS_OF_USE,
  PRIVACY_POLICY_PAGE,
} from "../../Routes/RoutesConstant";

export const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    email: Cookies.get("email"),
    password: Cookies.get("password"),
  });
  const [remember_me, setRememberMe] = useState(false);

  const [currentYear, setcurrentYear] = useState();

  const getYear = () => setcurrentYear(new Date().getFullYear());

  useEffect(() => {
    getYear();
  }, []);

  useEffect(() => {
    document.title = "MOV ||   LOGIN";
  }, []);

  const handleRememberMe = (e) => {
    const isChecked = e.target.checked;
    setRememberMe(isChecked);
  };
  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const showPassword =()=>{
    const show = document.getElementById('show')
    const hide = document.getElementById('hide')
    const password = document.getElementById('inputPassword')
    show.style.display = 'none'
    hide.style.display = 'block'
    password.setAttribute('type','text')
  }
  const hidePassword =()=>{
    const show = document.getElementById('show')
    const hide = document.getElementById('hide')
    const password = document.getElementById('inputPassword')
    show.style.display = 'block'
    hide.style.display = 'none'
    password.setAttribute('type','password')
  }

  // ======= submit Login request ==============
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      if (remember_me) {
        Cookies.set("email", values.email);
        Cookies.set("password", values.password);
        Cookies.set("remember_me", remember_me);
      }
      const response = await dispatch(loginAction(values));
      const loginData = response?.payload;
      if (loginData) {
        if (loginData?.data.status === true) {
          setItemLocalStorage(
            "accessToken",
            loginData.data.data.auth.accessToken
          );
          setItemLocalStorage(
            "refreshToken",
            loginData.data.data.auth.refreshToken
          );
          setItemLocalStorage("userId", loginData.data.data.id);
          history.push("/home");
        } else {
          swal({
            title: "error!",
            text: loginData?.data?.message,
            buttons: false,
            icon: "error",
            timer: 2000,
          });
        }
      }
    } catch (err) {
      swal({
        title: "Invalid Credentials",
        text: err?.message,
        buttons: false,
        icon: "error",
        timer: 2000,
      });
    }
  };

  return (
    <>
      <div className="login_logo">
        <img src={mov_logo} alt="logo" />
      </div>
      <div className="login_contaoner">
        <div className="login_wrapper_form">
          <form className="login_form" onSubmit={handleSubmit}>
            <p className="login_form_title">Sign into your account</p>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Email</label>
              <input
                type="email"
                className="form-control"
                name="email"
                required
                pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group" style={{position: "relative"  }}>
              <label htmlFor="exampleInputPassword1">Password</label>
              <input
                type="password"
                className="form-control"
                id="inputPassword"
                name="password"
                required
                onChange={handleInputChange}
              />
              <i className="bi bi-eye" id="hide" style={{position:'absolute',top:35,right:10, fontSize:'25px', display:'none', cursor:'pointer'}} onClick={hidePassword}></i>
              <i className="bi bi-eye-slash" id="show" style={{position:'absolute',top:35,right:10, fontSize:'25px',cursor:'pointer' }} onClick={showPassword}></i>
              

              <a
                className="forgetlink"
                onClick={() => history.push("/forgot-password")}
              >
                Forgot Password?
              </a>
            </div>
            {/* <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                onChange={handleRememberMe}
              />
              <label className="form-check-label remember_me" for="exampleCheck1">
                Remember Me
              </label>
            </div> */}
            <button type="submit" className="btn btn_submit">
              Sign In
            </button>
            <hr />
            <p className="login_text">
              Don't have an account?
              {/* <a href="http://3.12.67.137/" target="_blank"> */}
              <a href="https://movhealth.com/" target="_blank">
                {" "}
                Join Now!
              </a>
            </p>
          </form>
        </div>
      </div>
      <div className="form_futter">
        <div className="form_futter_top">
          {/* <Link to={TERMS_OF_USE}>Terms of Service</Link> */}
          <Link to={PRIVACY_POLICY_PAGE}>Web & Mobile Privacy Policy</Link>
          <Link to={PRIVACY_PRACTICE}>Notice of Privacy Practices</Link>
        </div>
        <div className="form_futter_bottom">
          <img src={copyright} alt="" /> {currentYear} MOV Health
        </div>
      </div>
    </>
  );
};
