import logo from './logo.svg';
import React from "react";
import './App.css';
import { Routes } from './Routes';
import { Loader } from './Components/loader/Loader';

function App() {
  return (
    <div className="App">
      <div className="page-wrapper">
        <Routes/>
      </div>
      <Loader/> 
    </div>
  );
}
 
export default App;
