import React from "react";
import "./dashboardView.css";

import { useEffect, useState } from "react";
import {
  getUserOutdoorAirQualityGraphAction,
  getUserIndoorAirQualityGraphAction,
  getUserAllergensGraphAction,
  getPafDataGraphAction,
  getPafDataGraphNewAction,
  getInhelarTotalWeekDataGraphAction,
  getOverallAveragesAction,
  getTodayOverallAveragesAction,
  getUserDetailsGraphpageAction,
  getReliefInhalerGraphAction,
  thisWeekInhalerCountAction,
} from "../../../Redux/Actions";
import moment from "moment";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { getItemLocalStorage } from "../../../Utils/Util";
import ReactToPdf from "react-to-pdf";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import RelifChart from "../relif-chart/RelifChart";
import { Link } from "react-router-dom";
const ref = React.createRef();

const barColors = ["#1f77b4", "#ff160e", "#2ca02c"];

const DashboardView = () => {
  const dispatch = useDispatch();
  const [filterValue, setFilterValue] = useState("");
  const [barOutdoorAirGraphLabels, setBarOutdoorAirGraphLabels] = useState([]);
  const [barOutdoorAirGraphValues, setBarOutdoorAirGraphValues] = useState([]);
  const [barIndoorAirGraphLabels, setBarIndoorAirGraphLabels] = useState([]);
  const [barIndoorAirGraphValues, setBarIndoorAirGraphValues] = useState([]);
  const [barAllergensGraphLabels, setBarAllergensGraphLabels] = useState([]);
  const [barAllergensGraphValues, setBarAllergensGraphValues] = useState([]);
  const [barPafDataGraphLabels, setBarPafDataGraphLabels] = useState([]);
  const [barPafDataGraphValues, setBarPafDataGraphValues] = useState([]);
  const [bestBarPafDataGraphValues, setBestBarPafDataGraphValues] = useState(
    []
  );
  const [worstBarPafDataGraphValues, setWorstBarPafDataGraphValues] = useState(
    []
  );
  const [barPafDataGraphNewValues, setBarPafDataGraphNewValues] = useState([]);
  const [averages, setAverages] = useState(0);
  const [averagesPaf, setAveragesPaf] = useState(0);
  const [pafdate, setPafDate] = useState('');
  const [riskLevelsTextColor, setRiskLevelsTextColor] = useState("");
  const [riskLevelsText, setRiskLevelsText] = useState("");
  const [outDoorAqiText, setOutDoorAqiText] = useState("");
  const [outDoorAqiTextColor, setOutDoorAqiTextColor] = useState("");
  const [inDoorAqiText, setInDoorAqiText] = useState("");
  const [inDoorAqiTextColor, setInDoorAqiTextColor] = useState("");
  const [userDetailsGraphpage, setUserDetailsGraphpage] = useState({});
  const [inhelarTotalWeekDataGraphpage, setInhelarTotalWeekDataGraphpage] =
    useState({});
  const [barReliefInhalerLabels, setBarReliefInhalerLabels] = useState([]);
  const [barReliefInhalerValues, setBarReliefInhalerValues] = useState([]);
  const [value, setValue] = useState("");
  var currentWeek = moment().startOf("isoWeek").format("YYYY-MM-DD");
  const [dateFilter, setDateFilter] = useState(currentWeek);

  const [overFilter, setOverFilter] = useState("");
  const displayData = useSelector(
    (state) => state.authReducer.userDataDisplay?.data?.data
  );
  const [permission, setPermission] = useState();
  const [thisWeekInhalerCount, setThisWeekInhalerCount] = useState();
  const [thisWeekInhalerCountColor, setThisWeekInhalerCountColor] =
    useState("");
  const [pafAverageTextColor, setPafAverageTextColor] = useState("");
  useEffect(() => {
    document.title = 'MOV || DASHBOARD'
  },[])
  useEffect(() => {
    if (displayData) {
      let dataStr = JSON.parse(displayData.permissions);
      setPermission(dataStr);
      // let parseData = JSON.parse(dataStr);
      // setPermission(parseData);
    }
  }, [displayData]);

  useEffect(() => {
    getPafDataGraph();
    getPafDataGraphNew();
    getReliefInhalerGraph();
  }, [filterValue]);

  useEffect(() => {
    getTodayOverallAverages();
    getUserDetailsGraphpage();
    getInhelarTotalWeekDataGraphpage();
    getThisWeekInhalerCount();
  }, []);

  const getThisWeekInhalerCount = async () => {
    try {
      const response = await dispatch(
        thisWeekInhalerCountAction({ userId: getItemLocalStorage("userId") })
      );
      const thisWeekInhalerCountSuccess = response?.payload;
      // console.log("====THIS WEEK===",thisWeekInhalerCountSuccess?.data?.data[0].inhalerCount);
      if (thisWeekInhalerCountSuccess) {
        if (thisWeekInhalerCountSuccess?.status == 200) {
          setThisWeekInhalerCount(
            thisWeekInhalerCountSuccess?.data?.data[0].inhalerCount
          );

          if (thisWeekInhalerCountSuccess?.data?.data[0].inhalerCount <= 1) {
            setThisWeekInhalerCountColor("text-green");
          }
          if (
            thisWeekInhalerCountSuccess?.data?.data[0].inhalerCount > 1 &&
            thisWeekInhalerCountSuccess?.data?.data[0].inhalerCount <= 2
          ) {
            setThisWeekInhalerCountColor("text-yellow");
          }
          if (thisWeekInhalerCountSuccess?.data?.data[0].inhalerCount > 2) {
            setThisWeekInhalerCountColor("text-red");
          }
        } else {
          swal({
            title: "Error!",
            text: thisWeekInhalerCountSuccess?.data?.message,
            buttons: false,
            icon: "error",
            timer: 2000,
          });
        }
      }
    } catch (err) {
      swal({
        title: "Error",
        text: err?.message,
        buttons: false,
        icon: "error",
        timer: 2000,
      });
    }
  };

  const getUserDetailsGraphpage = async () => {
    try {
      const response = await dispatch(
        getUserDetailsGraphpageAction({ userId: getItemLocalStorage("userId") })
      );
      const userDetailsGraphpageSuccess = response?.payload;
      if (userDetailsGraphpageSuccess) {
        if (userDetailsGraphpageSuccess?.status == 200) {
          setUserDetailsGraphpage(userDetailsGraphpageSuccess?.data?.data);
        } else {
          swal({
            title: "Error!",
            text: userDetailsGraphpageSuccess?.data?.message,
            buttons: false,
            icon: "error",
            timer: 2000,
          });
        }
      }
    } catch (err) {
      swal({
        title: "Error",
        text: err?.message,
        buttons: false,
        icon: "error",
        timer: 2000,
      });
    }
  };
  const getInhelarTotalWeekDataGraphpage = async () => {
    try {
      const response = await dispatch(
        getInhelarTotalWeekDataGraphAction({
          userId: getItemLocalStorage("userId"),
          date: dateFilter,
        })
      );
      const InhelarTotalWeekDataGraphpageSuccess = response?.payload;
      if (InhelarTotalWeekDataGraphpageSuccess) {
        if (InhelarTotalWeekDataGraphpageSuccess?.status == 200) {
          setInhelarTotalWeekDataGraphpage(
            InhelarTotalWeekDataGraphpageSuccess?.data?.data
          );
        } else {
          swal({
            title: "Error!",
            text: InhelarTotalWeekDataGraphpageSuccess?.data?.message,
            buttons: false,
            icon: "error",
            timer: 2000,
          });
        }
      }
    } catch (err) {
      swal({
        title: "Error",
        text: err?.message,
        buttons: false,
        icon: "error",
        timer: 2000,
      });
    }
  };

  const getPafDataGraph = async () => {
    try {
      const response = await dispatch(
        getPafDataGraphAction({
          userId: getItemLocalStorage("userId"),
          filter: value,
        })
      );

      const userPafDataGraphSuccess = response?.payload;
      if (userPafDataGraphSuccess) {
        if (userPafDataGraphSuccess?.status == 200) {
          setBarPafDataGraphLabels(
            userPafDataGraphSuccess?.data?.data?.map((i) => {
              return i.label;
            })
          );

          const DisplayData = userPafDataGraphSuccess?.data?.data;

          DisplayData.map((f, i) => {
            if (i === 1) {
              setBestBarPafDataGraphValues(f?.value);
              // console.log("best barPafDataGraphValues", bestBarPafDataGraphValues);
            }
          });

          DisplayData.map((f, i) => {
            if (i === 2) {
              setWorstBarPafDataGraphValues(f?.value);
              // console.log("best worstBarPafDataGraphValues", worstBarPafDataGraphValues);
            }
          });

          DisplayData.map((f, i) => {
            if (i === 0) {
              let noDecimal = f?.value.toString().split(".");
              // console.log("no decimal", noDecimal[0]);
              // console.log("barPafDataGraphValues", barPafDataGraphValues);
              setBarPafDataGraphValues(noDecimal[0]);
            }
          });

          // setBarPafDataGraphValues(
          //   userPafDataGraphSuccess?.data?.data?.map((i) => {
          //     return i.value;
          //   })
          // );
        } else {
          swal({
            title: "Error!",
            text: userPafDataGraphSuccess?.data?.message,
            buttons: false,
            icon: "error",
            timer: 2000,
          });
        }
      }
    } catch (err) {
      swal({
        title: "Error",
        text: err?.message,
        buttons: false,
        icon: "error",
        timer: 2000,
      });
    }
  };
  const getPafDataGraphNew = async () => {
    try {
      const response = await dispatch(
        getPafDataGraphNewAction({
          userId: getItemLocalStorage("userId"),
          filter: value,
          date: dateFilter,
        })
      );

      const userPafDataGraphNewSuccess = response?.payload;
      if (userPafDataGraphNewSuccess) {
        if (userPafDataGraphNewSuccess?.status == 200) {
          let graphData = userPafDataGraphNewSuccess?.data?.data;
          if (value == "") {
            graphData.map((item, key) => {
              graphData[key].date = moment(item.date).format("dd");
            });
          }
          setBarPafDataGraphNewValues(userPafDataGraphNewSuccess?.data?.data);
        } else {
          swal({
            title: "Error!",
            text: userPafDataGraphNewSuccess?.data?.message,
            buttons: false,
            icon: "error",
            timer: 2000,
          });
        }
      }
    } catch (err) {
      swal({
        title: "Error",
        text: err?.message,
        buttons: false,
        icon: "error",
        timer: 2000,
      });
    }
  };

  const getReliefInhalerGraph = async () => {
    try {
      const response = await dispatch(
        getReliefInhalerGraphAction({
          userId: getItemLocalStorage("userId"),
          filter: value,
          date: dateFilter,
        })
      );
      const userReliefInhalerGraphSuccess = response?.payload;
      if (userReliefInhalerGraphSuccess) {
        if (userReliefInhalerGraphSuccess?.status == 200) {
          let graphData = userReliefInhalerGraphSuccess?.data?.data;
          if (value == "") {
            graphData.map((item, key) => {
              graphData[key].date = moment(item.date).format("dd");
            });
          }
          setBarReliefInhalerValues(userReliefInhalerGraphSuccess?.data?.data);
          // setBarReliefInhalerLabels(labels);
          // setBarReliefInhalerValues(values);
        } else {
          swal({
            title: "Error!",
            text: userReliefInhalerGraphSuccess?.data?.message,
            buttons: false,
            icon: "error",
            timer: 2000,
          });
        }
      }
    } catch (err) {
      swal({
        title: "Error",
        text: err?.message,
        buttons: false,
        icon: "error",
        timer: 2000,
      });
    }
  };

  const getTodayOverallAverages = async () => {
    try {
      const response = await dispatch(
        getTodayOverallAveragesAction({
          userId: getItemLocalStorage("userId"),
        })
      );
      const userOverallAveragesSuccess = response?.payload;
      if (userOverallAveragesSuccess) {
        if (userOverallAveragesSuccess?.status == 200) {
          const DisplayData = userOverallAveragesSuccess?.data?.data;

          // console.log("map--", DisplayData);
          // console.log(
          //   "map--",
          //   userOverallAveragesSuccess?.data?.data[2].outdoorCount
          // );

          const myData = [];

          DisplayData.map((f, i) => {
            if (i === 0) {
              setAverages(f?.inhalerCount);
              // console.log("map--", averages);
            }
          });
          DisplayData.map((f, i) => {
            if (i === 1) {
              let noDecimal = f?.pafCount.toString().split(".");
              setAveragesPaf(noDecimal[0]);
              setPafAverageTextColor(f.pafColour)

              // if (noDecimal[0] >= 80) {
              //   setPafAverageTextColor("text-green");
              // }
              // if (noDecimal[0] > 60 && noDecimal[0] < 80) {
              //   setPafAverageTextColor("text-yellow");
              // }
              // if (noDecimal[0] < 60) {
              //   setPafAverageTextColor("text-red");
              // }
              let pafRecentDate = f?.pafRecentDate;
              setPafDate(pafRecentDate)
              // console.log(pafRecentDate, 'noDecimal')
              // console.log("map--", averagesPaf);
            }
          });

          if (
            userOverallAveragesSuccess?.data?.data[4].risk_Levels ===
            "No data found" || 'NO'
          ) {
            setRiskLevelsTextColor("text-black");
            setRiskLevelsText("No Data");
          }
          if (
            userOverallAveragesSuccess?.data?.data[4].risk_Levels === "POOR"
          ) {
            setRiskLevelsTextColor("text-red");
            setRiskLevelsText("POOR");
          }
          if (
            userOverallAveragesSuccess?.data?.data[4].risk_Levels === "MODERATE"
          ) {
            setRiskLevelsTextColor("text-yellow");
            setRiskLevelsText("FAIR");
          }
          if (
            userOverallAveragesSuccess?.data?.data[4].risk_Levels === "GOOD"
          ) {
            setRiskLevelsTextColor("text-green");
            setRiskLevelsText("GOOD");
          }
          if (Number(userOverallAveragesSuccess?.data?.data[2].outdoorCount) <= 0) {
            setOutDoorAqiText("No Data");
            setOutDoorAqiTextColor("text-black");
          }
          if (
            Number(userOverallAveragesSuccess?.data?.data[2].outdoorCount) > 0 &&
            Number(userOverallAveragesSuccess?.data?.data[2].outdoorCount) <= 50
          ) {
            setOutDoorAqiText("GOOD");
            setOutDoorAqiTextColor("text-green");
          }
          if (
            Number(userOverallAveragesSuccess?.data?.data[2].outdoorCount) > 51 &&
            Number(userOverallAveragesSuccess?.data?.data[2].outdoorCount) <= 100
          ) {
            setOutDoorAqiText("FAIR");
            setOutDoorAqiTextColor("text-yellow");
          }
          if (Number(userOverallAveragesSuccess?.data?.data[2].outdoorCount) >= 101) {
            setOutDoorAqiText("POOR");
            setOutDoorAqiTextColor("text-red");
          }
          if (Number(userOverallAveragesSuccess?.data?.data[3].indoorCount) <= 0) {
            setInDoorAqiText("No Data");
            setInDoorAqiTextColor("text-black");
          }
          if (
            Number(userOverallAveragesSuccess?.data?.data[3].indoorCount) >= 1 &&
            Number(userOverallAveragesSuccess?.data?.data[3].indoorCount) <= 40
          ) {
            setInDoorAqiText("GOOD");
            setInDoorAqiTextColor("text-green");
          }
          if (
            Number(userOverallAveragesSuccess?.data?.data[3].indoorCount) > 40 &&
            Number(userOverallAveragesSuccess?.data?.data[3].indoorCount) <= 75
          ) {
            setInDoorAqiText("FAIR");
            setInDoorAqiTextColor("text-yellow");
          }
          if (Number(userOverallAveragesSuccess?.data?.data[3].indoorCount) >= 75) {
            setInDoorAqiText("POOR");
            setInDoorAqiTextColor("text-red");
          }
        } else {
          swal({
            title: "Error!",
            text: userOverallAveragesSuccess?.data?.message,
            buttons: false,
            icon: "error",
            timer: 2000,
          });
        }
      }
    } catch (err) {
      swal({
        title: "Error",
        text: err?.message,
        buttons: false,
        icon: "error",
        timer: 2000,
      });
    }
  };

  const overFilterChange = () => {
    setOverFilter("");
  };
  const changeStyle = (e) => {
    var currentWeek = moment().startOf("isoWeek").format("YYYY-MM-DD");

    setValue("");
    setDateFilter(currentWeek);
  };
  const printDocument = () => {
    html2canvas(ref.current, {
      width: 1200,
      height: 1200,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 0, 0);
      pdf.save("download.pdf");
    });
  };

  const currentDate = new Date();
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const TodayDate = currentDate.toLocaleDateString("en-us", options);

  const renderInhalerGraph = () => {
    return barReliefInhalerValues.map((entry, index) => {
      let colorCode = "";
      if (entry.avgUses <= 1) {
        colorCode = "#68F16C";
      }
      if (entry.avgUses > 1 && entry.avgUses <= 2) {
        colorCode = "#F1F576";
      }
      if (entry.avgUses > 2) {
        colorCode = "#FF4747";
      }
      return (
        <>
          <Cell key={`cell-${index}`} fill={colorCode} />
        </>
      );
    });
  };

  const renderPafGraph = () => {
    return barPafDataGraphNewValues.map((entry, index) => {
      let colorCode = entry.averageCountPafColour ==="GREEN" ? '#68f16c' : entry.averageCountPafColour === 'YELLOW' ? '#fef8a0' : entry.averageCountPafColour === 'RED' ? '#e61610' : '#a2a2a2';
      // if (entry.averageCount <= 60) {
      //   colorCode = "#FF4747";
      // }
      // if (entry.averageCount > 60 && entry.averageCount <= 80) {
      //   colorCode = "#F1F576";
      // }
      // if (entry.averageCount > 80) {
      //   colorCode = "#68F16C";
      // }
      return (
        <>
          <Cell key={`cell-${index}`} fill={colorCode} />
        </>
      );
    });
  };

  return (
    <>
      <div className="dashboard_view_main">
        <div className="view_title">
          <div className="view_title_left">
            Hi, {userDetailsGraphpage?.fullname}!
          </div>
        </div>
        <div className="view_body">
          <div className="view_body_top"></div>
          <div className="view_body_bottom">
            <div className="view_body_bottom1">
              <u> Relief Inhaler</u>
              <br />
              <div
                className={thisWeekInhalerCountColor}
                style={{ marginBottom: 0 }}
              >
                {thisWeekInhalerCount}
              </div>
              <div
                className="today"
                style={{ color: "gray", fontStyle: "italic" }}
              >
                Uses This Week
              </div>
            </div>

            <div className="view_body_bottom2">
              <u>Most Recent PAF</u>
              <br />
              <div className={pafAverageTextColor === "GREEN" ? 'text-green' : pafAverageTextColor === 'YELLOW' ? 'text-yellow' : pafAverageTextColor === 'RED'? 'text-red' : 'text-black'}>
                {averagesPaf + " L/m"}
              </div>

              <div
                className="today"
                style={{ color: "gray", fontStyle: "italic" }}
              >
                {moment(pafdate).format("LL")}
              </div>
            </div>
            <div className="view_body_bottom3">
              <u>Current Outdoor AQI</u>
              <br />

              <div className={outDoorAqiTextColor}>{outDoorAqiText}</div>
            </div>
            <div className="view_body_bottom4">
              <u>Current Indoor AQI</u>
              <br />
              <div className={inDoorAqiTextColor}>{inDoorAqiText}</div>
            </div>
            <div className="view_body_bottom5">
              <u>Current Allergen Levels</u>
              <br />
              <div className={riskLevelsTextColor}>{riskLevelsText}</div>
            </div>
          </div>
        </div>
        <div className="view_charts">
          <div className="view_chart_one">
            <div className="view_chart_top">Weekly Snapshot</div>
            <div className="view_chart_middle">
              <div className="view_chart_middle_top">Peak Air Flow (PAF)</div>
              <div className="view_chart_middle_body">
                <div className="view_chart_middle_body_left">
                  <ResponsiveContainer>
                    <BarChart
                      onChange={changeStyle}
                      data={barPafDataGraphNewValues}
                      margin={{
                        top: 5,
                        right: 10,
                        left: 0,
                        bottom: 5,
                      }}
                    >
                      <XAxis dataKey="date" />
                      <YAxis />
                      <Tooltip cursor={{ fill: "transparent" }} />

                      <Bar dataKey="averageCount" name="PAF">
                        {renderPafGraph()}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </div>
                <div className="view_chart_middle_body_right">
                  <div
                    className="view_chart_middle_body_right1"
                    style={{ marginTop: "20px" }}
                  >
                    <u>Best</u>
                    <br />
                    {bestBarPafDataGraphValues} L/m
                  </div>
                  <div
                    className="view_chart_middle_body_right2"
                    style={{ marginTop: "20px" }}
                  >
                    <u>Worst </u>
                    <br />
                    {worstBarPafDataGraphValues} L/m
                  </div>
                  <div
                    className="view_chart_middle_body_right3"
                    style={{ marginTop: "20px" }}
                  >
                    <u>Average</u>
                    <br />
                    {barPafDataGraphValues} L/m
                  </div>
                </div>
              </div>
            </div>
            <div className="view_chart_middle">
              <div className="view_chart_middle_top">Relief Inhaler Use</div>
              <div className="view_chart_middle_body">
                <div className="view_chart_middle_body_left">
                  {/* <RelifChart /> */}
                  <ResponsiveContainer>
                    <BarChart
                      onChange={changeStyle}
                      data={barReliefInhalerValues}
                      margin={{
                        top: 5,
                        right: 10,
                        left: 0,
                        bottom: 5,
                      }}
                    >
                      <XAxis dataKey="date" />
                      <YAxis />
                      <Tooltip cursor={{ fill: "transparent" }} />
                      <Bar dataKey="avgUses" fill="" name="uses">
                        {renderInhalerGraph()}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </div>
                <div className="view_chart_middle_body_right">
                  <div
                    className="view_chart_middle_body_right2"
                    style={{ marginTop: "80px" }}
                  >
                    <u>Total </u>
                    <br />
                    {inhelarTotalWeekDataGraphpage.totalUses} Uses
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="view_chart_two">
            <div className="view_chart_quick">
              <div className="view_chart_quick_top">
                <u>Quick Fact</u>
              </div>
              <div className="view_chart_quick_body">
                Allergies and Asthma go hand in hand. Many Asthma sufferers are
                allergic to Pollen, an extremely abundant allergen originating
                from Weeds, Trees and Grasses that travel through the air. Every
                notice that your Asthma gets worse during the Spring and Fall?
                Those are usually the worst times of year for pollen counts.
              </div>
            </div>
            <div className="view_chart_asthma">
              <div className="view_chart_asthma_top">
                Tell us a bit about your Asthma
              </div>
              <div className="view_chart_asthma_body">
                This will help us better understand your condition so we can
                provide more personalized feedback in real time!
              </div>
              <Link
                style={{ textDecoration: "none", color: "white!important" }}
                className="view_chart_asthma_bottom text-white"
                to={"/my-asthamadata"}
              >
                My Asthma
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardView;
