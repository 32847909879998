import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "../Reducers/RootReducer";

// window.onbeforeunload = function () {
//   localStorage.clear();
// };

function clearStorage() {
  let session = sessionStorage.getItem("accessToken");

  if (session == null) {
    localStorage.removeItem("accessToken");
    window.location.href = "/";
  }
  sessionStorage.setItem("accessToken", 1);
}
// window.addEventListener("load", clearStorage);

// window.onbeforeunload = function (e) {
//   window.onunload = function () {
//     window.localStorage.isMySessionActive = "false";
//   };
// };
// window.onload = function () {
//   window.localStorage.isMySessionActive = "true";
// };
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["loginData"], // which reducer want to store
  // blacklist: ['']  // which reducer do not want to store/
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk))
);
export const persistor = persistStore(store);
export default store;
