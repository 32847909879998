import React, {useEffect} from "react";
import {
    TabContainer,
    TabPane,
    TabContent,
    Row,
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
} from "react-bootstrap";

const PrivayPractices = () => {
    useEffect(() => {
        document.title = 'MOV || PRIVACY PRACTICES'
      },[])
    return (
        <Container className='px-md-5'>
            <Row>
                <Col className="col-12 text-center">
                    <h2 className="my-5 login_form_title2">MOV Web & Mobile Privacy Practices</h2>
                </Col>
            </Row>
            <Row>
                <Col className="login_text">
                    <p>
                        THIS NOTICE DESCRIBES HOW INFORMATION YOU PROVIDE TO MOV MAY BE USED AND DISCLOSED AND HOW YOU
                        CAN GET ACCESS TO THIS INFORMATION. PLEASE REVIEW IT CAREFULLY.</p>
                    <p>
                        MOV Health LLC (“MOV”) is committed to keeping your health information safe. To help you understand your rights
                        to your health information, please review this policy. We are required by law to have this privacy policy and
                        maintain your health information in a manner consistent with this policy and law. This notice is in five parts to
                        describe our privacy practices. We hope through this policy that we answer any questions you have about how MOV
                        maintains your health information. The sections are as follows:
                    </p>
                    <div className="mx-5">
                        <p>1) What is Protected Health Information (“PHI”)?</p>
                        <p>2) What type of PHI does MOV collect?</p>
                        <p>3) Who does MOV share my PHI with and why?</p>
                        <p> 4) What are my rights to my PHI?</p>
                        <p>5) What should I do if I have a question or concern about my collected PHI?</p>
                    </div>
                    <div>
                        <h4 className="mt-4">What is Protected Health Information?</h4>
                        <p> MOV receives and maintains certain personal information about all our members. Some of this personal information
                            is protected by federal and state laws. This type of information is known as “protected health information” or “PHI”.
                            PHI is health information
                            that identifies or could be used to identify a specific
                            individual
                        </p>
                    </div>
                    <div>
                        <h4 className="mt-4">What type of PHI does MOV collect?</h4>
                        <p>When you voluntarily give your PHI to MOV through our online website
                            , mobile application,
                            and through your use of
                            our MOV programs
                            ,
                            we maintain such
                            PHI in our secure systems. Examples of
                            the type of
                            PHI you may provide to
                            MOV
                            include:</p>
                        <ul className="pl-5">
                            <li className="my-2">
                                When you choose to register for the MOV programs, you provide your personal information such as your
                                name, address, height, weight, medical history, and/or insurance information.</li>
                            <li className="my-2">
                                When you use the MOV programs, we will receive health information from you such as your allergies,
                                medications, medication use, and
                                biometrics.</li>
                            <li className="my-2">
                                We may also collect your prescription, lab and claims data from available information sources to provide you with a more personal experience while using the MOV programs.</li>
                        </ul>
                    </div>
                    <div className="mt-4">
                        <h4>Who does MOV share my PHI with and why?</h4>
                        <p>We use or disclose your PHI for treatment, payment, or healthcare operations purposes and other purposes
                            permitted or required by law. By registering for the MOV program, you authorize MOV to use or disclose your PHI
                            for such purposes, which are described
                            below. We need your written authorization to use or disclose your health
                            information for any purpose not covered by one of the categories below. We will not use or disclose your PHI for
                            marketing purposes or sell your PHI, unless you have agreed to this us
                            e or disclosure.</p>
                        <p>
                            You can inform us at any time that you no longer allow us to use or disclose your PHI for the reasons shown below,
                            but this will not stop any disclosure that we made based on your prior authorization. The law permits us to use and
                            disclose your health information for the following purposes:
                        </p>
                        <ul className="pl-3">
                            <li className="my-3">
                                Treatment: We may use or disclose your PHI to healthcare professionals for
                                treatment purposes. This includes to
                                the clinical teams at your employer, health plan, and/or pharmacy benefits managers to ex
                                tent such group
                                sponsors your use of the MOV program (i.e., the MOV benefit is paid for by your employer, health plan, etc.)
                            </li>
                            <li className="my-3">
                                Payment
                                –
                                We may use or disclose your PHI for purposes of billing and payment for the MOV programs. For
                                example, we may disclose
                                your PHI to your pharmacy benefits manager, health plans or other payers to
                                determine whether you are enrolled with the payer or eligible for health benefits or to get payment for our
                                services. If you are insured under another person’s health insurance po
                                licy (for example, parent, spouse,
                                domestic partner, or a former spouse), we may also send invoices to the subscriber whose policy covers your
                                health services.
                            </li>
                            <li className="my-3">
                                Healthcare Operations
                                -
                                We may use or disclose your PHI for activities necessary to support ou
                                r healthcare
                                operations, such as performing quality checks on our services, internal audits, arranging for legal services, data
                                analysis or developing reference ranges for our services. We also disclose your PHI to your health plan,
                                employer’s clinical tea
                                m, health care benefits consultant, or benefits manager clinical team, if your use of the
                                MOV services is available and paid for by one of those groups. We provide only the minimal PHI to accomplish
                                the intended purpose of the use and disclosure of the PHI
                                . These entities are also required to keep the PHI
                                confidential and secure.
                            </li>
                            <li className="my-3">
                                Business Associates
                                -
                                We may disclose your PHI to other companies or individuals that need the information to
                                provide services to us. These other entities, known as "business ass
                                ociates," are required to also keep the PHI
                                confidential and secure. For example, we may provide information to companies that assist us with support
                                services or billing of our services.
                            </li>
                            <li className="my-3">
                                De
                                -
                                identifiable and Aggregated Format
                                –
                                We may use and disclose your PHI in a de
                                -
                                identifiable and aggregated
                                manner to review our impact on all our
                                Members health and in hopes of making the MOV programs even more
                                effective to help you with your management
                                of your chronic condition.
                            </li>
                            <li className="my-3">
                                Research
                                -
                                We may also use and disclose PHI for research purposes when an Institutional Review Board or
                                privacy board has reviewed the research proposal and established protocols to ensure the privacy of your PHI
                                and determined
                                that the researcher does not need to obtain your authorization prior to using your PHI for
                                research purposes
                            </li>
                            <li className="my-3">
                                As Required by Law - We may use or disclose your PHI as required by law.
                            </li>
                            <li className="my-3">

                                Law Enforcement Activities, Legal Proceedings and Court Orders
                                -
                                We
                                may use and disclose your PHI to prevent
                                or minimize a serious threat to your health and safety or that of another person. We may also provide PHI to law
                                enforcement officials, for example, in response to a warrant, investigative demand or similar legal pr
                                ocess, or for officials to identify or locate a suspect, fugitive, material witness, or missing person. We may also disclose PHI to
                                appropriate agencies if we reasonably believe an individual to be a victim of abuse, neglect or domestic violence.
                                We may disclose your PHI if required to do so with a court or administrative order. We may disclose your PHI in
                                response to a subpoena, discovery request or other legal process during a judicial or administrative proceeding.
                                We may also disclose PHI to those assist
                                ing in disaster relief efforts so that others can be notified about your
                                condition, status and location.
                            </li>
                            <li className="my-3">

                                Family and Friends – At your request, we may disclose PHI to a family member, friend, caregiver, or anyone else you inform us to provide the information to.
                            </li>
                            <li className="my-3"> Other Uses and Disclosures: As permitted by HIPAA, we may disclose your PHI to:
                                <ul className="pl-3 my-2">
                                    <li>
                                        Public Health Authorities
                                    </li>
                                    <li>
                                        The Food and Drug Administration
                                    </li>
                                    <li>
                                        Health Oversight Agencies
                                    </li>
                                    <li>
                                        Military Command Authorities
                                    </li>
                                    <li>
                                        National Security and Intelligence Organizations
                                    </li>
                                    <li>
                                        Correctional Institutions
                                    </li>
                                    <li>
                                        Organ and Tissue Donation Organizations
                                    </li>
                                    <li>
                                        Coroners, Medical Examiners and Funeral Directors
                                    </li>
                                    <li>
                                        Workers Compensation Agents
                                    </li>
                                </ul>
                            </li>

                        </ul>
                    </div>
                    <div className="mt-4">
                        <h4>What are my rights to my PHI?</h4>
                        <p>You have rights to your PHI that we collect. You can request MOV restrict the use and disclosure of your PHI by
                            sending a written request to the address below.</p>
                        <p>You can access your PHI we created or PHI you provided us online at any time by logging in at
                            member.movhealth.com or you can request we send your health information by alternative means to an alternative
                            address. Once you review your PHI, if you see any problems with your PHI, you may request amendments to your
                            PHI by making a written request to us
                            at the address below. We may deny the request in some cases. If we deny
                            your request to change your PHI we will provide you with a written explanation of the reason for the denial and
                            additional information regarding further actions that you may take. You also have the right to receive a list of certain disclosures of your PHI made by us in the past six years from the date of your written request to us at the address
                            below. Under the law, this does not include disclosures made for purposes of treatment, payment, or healthcare operations or the other certain other purposes we have stated above. Please be aware that we are required as
                            stated in the Health Insurance Portability and Accountability Act (HIPAA) of 1996 to notify you in the event of a
                            breach involving your PHI and will do so as required by law.</p>
                        <p>You have the right to obtain a paper copy of this Privacy Policy by written request to the address below.</p>
                    </div>
                    <div className="mt-4">
                        <h4>What should I do if I have a question or concern about my collected PHI?</h4>
                        <p>If you believe your privacy rights have been violated, you have the right to file a complaint with us. You also have the
                            right to file a complaint with the Secretary of the U.S. Department of Health and Human Services, Office for Civil
                            Rights. We will not
                            retaliate against any individual for filing a complaint. To file a complaint with us, or should you have any questions about this Privacy Policy and Notice of Privacy Practices, send an email to our
                            Privacy
                            Department
                            at
                            info@movhealth.com,
                            or write to us
                            at the following address:
                        </p>
                        <span>Privacy Department<br />
                            MOV Health LLC<br />
                            1968 S. Coast Hwy<br />
                            Suite
                            5407<br />
                            Laguna Beach CA 92651
                        </span>
                    </div>
                    <div className="mt-4">
                        <h4>Changes to the Privacy Policy</h4>
                        <p>We reserve the right to amend the terms of this Privacy Policy and Notice of Privacy Practices to reflect change
                            s in
                            our privacy practices, and to make the new terms and practices applicable to all PHI that we maintain about you,
                            including PHI created or received prior to the effective date of the Privacy Policy and Notice of Privacy Practices
                            revision. Our Privacy
                            Policy and Notice of Privacy Practices is displayed on our website and a copy is available upon
                            request.
                            As such, it is your responsibility to
                            check back periodically
                            to review any changes.
                        </p>
                        Last
                        Updated:
                        January 1, 2022
                    </div>

                </Col>
            </Row>
        </Container >
    )
}
export default PrivayPractices