import React from "react";
import {
  TabContainer,
  TabPane,
  TabContent,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
} from "react-bootstrap";
import SideBar from "../Common/SideBar";
import { TopBar } from "../Common/TopBar";
import MyAccount from "../Dashboard/my-account/MyAccount";

const Account = () => {
  return (
    <>
      <TopBar />
      <TabContainer
        id="left-tabs-example"
        defaultActiveKey="first"
        className="container-fluid"
      >
        <Row className="home-tabs">
          <Col xl={3} className="sidebar">
            <SideBar />
          </Col>

          <Col xl={12} className="right-bar">
            <TabContent>
              <TabPane eventKey="first">
                <MyAccount />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </TabContainer>
    </>
  );
};

export default Account;
