import React from "react";
import mov_logos from "../../Assets/images/dashboard/Mov_logos_png_11.png";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { logoutAction } from "../../Redux/Actions";
import { RiChat1Line } from "react-icons/ri";
import { Link } from "react-router-dom";

export const TopBar = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loginDetails = useSelector((state) => {
    return state.authReducer.userData;
  });

  const logout = async () => {
    try {
      const response = await dispatch(logoutAction());
      console.log("response", response);
      const logoutData = response?.payload;
      if (logoutData) {
        if (logoutData == "LOGOUT") {
          history.push("/");
        } else {
          swal({
            title: "error!",
            text: logoutData?.data?.message,
            buttons: false,
            icon: "error",
            timer: 2000,
          });
        }
      }
    } catch (error) {
      swal({
        title: "Error",
        text: error?.message,
        buttons: false,
        icon: "error",
        timer: 2000,
      });
    }
  };
  return (
    <div className="pc-header page-header d-flex justify-content-between">
      <div className="logo-sec">
        <Link to={"/home"}>
          <img src={mov_logos} alt="logo" />
        </Link>
      </div>
      <div className="view_title_right">
        {/* <div className="view_title_right_support">
          <u>
            <RiChat1Line /> 24/7 Member Support
          </u>
        </div> */}
        <div className="view_title_right_logout">
          <button style={{ cursor: "pointer" }} type="button" onClick={logout}>
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};
