import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import "../Dashboard/resetpassword.css";
import mov_logo from "../../Assets/images/Mov_logo1.png";
import key_logo from "../../Assets/images/Vpn_Key_Icon_1.png";
import {
  resetPasswordAction,
  forgotPassworValidatedAction,
  updatePasswordAction,
} from "../../Redux/Actions";
import swal from "sweetalert";
import { getItemLocalStorage } from "../../Utils/Util";
import { AuthService } from "../../Services/AuthService";

const UpdateForgotPassword = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    keyId: props.match.params.id,
    newPassword: "",
    confirmPassword: "",
  });
  const [error, setError] = useState("");
  const confirmInput = useRef(null);
  useEffect(async () => {
    let response = await dispatch(
      forgotPassworValidatedAction({ keyId: props.match.params.id })
    );

    if (response.payload.status !== true) {
      swal({
        title: response.payload.status ? "Success" : "Error!",
        text: response.payload?.message,
        buttons: false,
        icon: response.payload?.status ? "success" : "error",
        timer: 2000,
      }).then((willDelete) => {
        setTimeout(() => {
          history.push("/");
        }, 2000);
      });
    }
  }, []);
  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setValues({
      ...values,
      [name]: value,
    });
  };

  // ======= submit Reset Password request ==============
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      const response = await dispatch(updatePasswordAction(values));
      const sendCode = response?.payload;
      if (sendCode) {
        if (sendCode?.status == 200) {
          swal({
            title: sendCode?.data?.status ? "Success" : "Error!",
            text: sendCode?.data?.message,
            buttons: false,
            icon: sendCode?.data?.status ? "success" : "error",
            timer: 2000,
          }).then((willDelete) => {
            setTimeout(() => {
              history.push("/");
            }, 1000);
          });
        } else {
          swal({
            title: "Error!",
            text: sendCode?.data?.message,
            buttons: false,
            icon: "error",
            timer: 2000,
          });
        }
      }
    } catch (err) {
      swal({
        title: "Error",
        text: err?.message,
        buttons: false,
        icon: "error",
        timer: 2000,
      });
    }
  };

  const handleOnBlur = (e) => {
    if (values.newPassword !== values.confirmPassword) {
      setError("Passwords do not match");
      confirmInput.current.focus();
    } else {
      setError("");
    }
  };

  return (
    <div className="login-contaoner">
      <div className="login_wrapper">
        <form className="login_form" onSubmit={handleSubmit}>
          <div className="logo_img">
            <img src={mov_logo} alt="logo" />
          </div>
          <div className="form-group">
            <label for="exampleInputPassword1">New Password</label>
            <div className="input_icon">
              <input
                type="password"
                className="form-control"
                id="exampleInputPassword1"
                name="newPassword"
                required
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="form-group">
            <label for="exampleInputPassword1">Confirm New Password</label>
            <div className="input_icon">
              <input
                type="password"
                className="form-control"
                id="exampleInputPassword2"
                name="confirmPassword"
                required
                ref={confirmInput}
                onBlur={handleOnBlur}
                onChange={handleInputChange}
              />
            </div>
            {error && <span className="error-text">{error}</span>}
          </div>
          <button type="submit" className="btn btn-submit">
            Change Password
          </button>
        </form>
      </div>
    </div>
  );
};
export default UpdateForgotPassword;
