import { AuthService } from "../../Services/AuthService";
import {
  LOGIN_SUCCESS,
  GET_PAFDATA_GRAPH_SUCCESS,
  GET_PAFDATA_GRAPH_NEW_SUCCESS,
  FORGOT_PASSWORD_SEND_CODE,
  RESET_PASSWORD_SUCCESS,
  LOGOUT_DETAIL_SUCCESS,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_NEW_SUCCESS,
  GET_USER_INDOOR_AIR_QUALITY_GRAPH_SUCCESS,
  UPDATE_USER_DETAILS_SUCCESS,
  GET_TODAY_OVERALL_AVERAGES_SUCCESS,
  UPDATE_USER_PROFILE_SUCCESS,
  GET_PEAKAIRDATA_GRAPH_SUCCESS,
  GET_INHELAR_WEEKLY_DATA_GRAPH_SUCCESS,
  UPDATE_USER_PROFILE_NEW_SUCCESS,
  GET_INHELAR_TOTAL_WEEK_DATA_GRAPH_SUCCESS,
  GET_USER_OUTDOOR_AIR_QUALITY_GRAPH_SUCCESS,
  GET_USER_ALLERGENS_GRAPH_SUCCESS,
  GET_USER_DETAILS_SUCCESS,
  GET_OVERALL_AVERAGES_SUCCESS,
  GET_USER_DETAILS_GRAPHPAGE_SUCCESS,
  FORGOT_PASSWORD_LINK_VALIDATE,
  UPDATE_PASSWORD_SUCCESS,
  THIS_WEEK_INHALER_COUNT_SUCCESS,
  GET_TERMS,
  GET_PRIVATE_POLICY
} from "./ActionTypes";
import { loadingAction } from "./LoadingAction";

export const loginAction = (data) => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return AuthService.login(data)
      .then((response) => {
        dispatch(loadingAction(false));
        dispatch({ type: LOGOUT_DETAIL_SUCCESS, payload: {} });
        return dispatch({ type: LOGIN_SUCCESS, payload: response });
      })
      .catch((err) => {
        dispatch(loadingAction(false));
        throw err;
      });
  };
};

export const logoutAction = () => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    localStorage.clear();
    dispatch({ type: LOGIN_SUCCESS, payload: "LOGOUT" });
    return dispatch({ type: LOGOUT_DETAIL_SUCCESS, payload: "LOGOUT" });
  };
};

export const forgotPasswordAction = (data) => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return AuthService.forgotPassword(data)
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({ type: FORGOT_PASSWORD_SEND_CODE, payload: response });
      })
      .catch((err) => {
        dispatch(loadingAction(false));
        throw err;
      });
  };
};
export const forgotPassworValidatedAction = (data) => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return AuthService.forgotPasswordLinkValidate(data)
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({
          type: FORGOT_PASSWORD_LINK_VALIDATE,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch(loadingAction(false));
        throw err;
      });
  };
};

export const resetPasswordAction = (data) => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return AuthService.resetPassword(data)
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({ type: RESET_PASSWORD_SUCCESS, payload: response });
      })
      .catch((err) => {
        dispatch(loadingAction(false));
        throw err;
      });
  };
};
export const updatePasswordAction = (data) => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return AuthService.updatePassword(data)
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({ type: UPDATE_PASSWORD_SUCCESS, payload: response });
      })
      .catch((err) => {
        dispatch(loadingAction(false));
        throw err;
      });
  };
};

export const getUserProfileAction = (data) => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return AuthService.getUserProfileDetail(data)
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({ type: GET_USER_PROFILE_SUCCESS, payload: response });
      })
      .catch((err) => {
        dispatch(loadingAction(false));
        throw err;
      });
  };
};

// create by meeee

export const getUserProfileNewAction = (data) => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return AuthService.getUserProfileNew(data)
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({
          type: GET_USER_PROFILE_NEW_SUCCESS,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch(loadingAction(false));
        throw err;
      });
  };
};

export const updateUserProfileNewAction = (data) => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return AuthService.updateUserProfileNew(data)
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({
          type: UPDATE_USER_PROFILE_NEW_SUCCESS,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch(loadingAction(false));
        throw err;
      });
  };
};
///end
export const getUserDetailAction = (data) => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return AuthService.getUserDetail(data)
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({ type: GET_USER_DETAILS_SUCCESS, payload: response });
      })
      .catch((err) => {
        dispatch(loadingAction(false));
        throw err;
      });
  };
};

export const updateUserDetailsAction = (data) => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return AuthService.updateUserDetail(data)
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({
          type: UPDATE_USER_DETAILS_SUCCESS,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch(loadingAction(false));
        throw err;
      });
  };
};

export const getUserOutdoorAirQualityGraphAction = (data) => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return AuthService.getUserOutdoorAirQualityGraph(data)
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({
          type: GET_USER_OUTDOOR_AIR_QUALITY_GRAPH_SUCCESS,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch(loadingAction(false));
        throw err;
      });
  };
};

export const getUserIndoorAirQualityGraphAction = (data) => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return AuthService.getUserIndoorAirQualityGraph(data)
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({
          type: GET_USER_INDOOR_AIR_QUALITY_GRAPH_SUCCESS,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch(loadingAction(false));
        throw err;
      });
  };
};

export const getUserAllergensGraphAction = (data) => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return AuthService.getUserAllergensGraph(data)
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({
          type: GET_USER_ALLERGENS_GRAPH_SUCCESS,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch(loadingAction(false));
        throw err;
      });
  };
};

export const getPafDataGraphAction = (data) => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return AuthService.getPafDataGraph(data)
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({ type: GET_PAFDATA_GRAPH_SUCCESS, payload: response });
      })
      .catch((err) => {
        dispatch(loadingAction(false));
        throw err;
      });
  };
};
export const getPeakAirDataGraphAction = (data) => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return AuthService.getPeakAirDataGraph(data)
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({
          type: GET_PEAKAIRDATA_GRAPH_SUCCESS,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch(loadingAction(false));
        throw err;
      });
  };
};
export const getInhelarWeeklyDataGraphAction = (data) => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return AuthService.getInhelarWeeklyDataGraph(data)
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({
          type: GET_INHELAR_WEEKLY_DATA_GRAPH_SUCCESS,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch(loadingAction(false));
        throw err;
      });
  };
};
export const getInhelarTotalWeekDataGraphAction = (data) => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return AuthService.getInhelarTotalWeekDataGraph(data)
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({
          type: GET_INHELAR_TOTAL_WEEK_DATA_GRAPH_SUCCESS,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch(loadingAction(false));
        throw err;
      });
  };
};
export const getPafDataGraphNewAction = (data) => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return AuthService.getPafDataGraphNew(data)
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({
          type: GET_PAFDATA_GRAPH_NEW_SUCCESS,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch(loadingAction(false));
        throw err;
      });
  };
};

export const getOverallAveragesAction = (data) => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return AuthService.getOverallAverages(data)
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({
          type: GET_OVERALL_AVERAGES_SUCCESS,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch(loadingAction(false));
        throw err;
      });
  };
};
export const getTodayOverallAveragesAction = (data) => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return AuthService.getTodayOverallAverages(data)
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({
          type: GET_TODAY_OVERALL_AVERAGES_SUCCESS,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch(loadingAction(false));
        throw err;
      });
  };
};

export const getUserDetailsGraphpageAction = (data) => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return AuthService.getUserDetailsGraphpage(data)
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({
          type: GET_USER_DETAILS_GRAPHPAGE_SUCCESS,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch(loadingAction(false));
        throw err;
      });
  };
};

export const getReliefInhalerGraphAction = (data) => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return AuthService.getReliefInhalerGraph(data)
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({
          type: GET_USER_ALLERGENS_GRAPH_SUCCESS,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch(loadingAction(false));
        throw err;
      });
  };
};

export const uploadProfileAction = (data) => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return AuthService.uploadProfile(data)
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({ type: GET_USER_PROFILE_SUCCESS, payload: response });
      })
      .catch((err) => {
        dispatch(loadingAction(false));
        throw err;
      });
  };
};

export const thisWeekInhalerCountAction = (data) => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return AuthService.thisWeekInhalerCount(data)
      .then((response) => {
        dispatch(loadingAction(false));
        dispatch({ type: THIS_WEEK_INHALER_COUNT_SUCCESS, payload: {} });
        return dispatch({ type: THIS_WEEK_INHALER_COUNT_SUCCESS, payload: response });
      })
      .catch((err) => {
        dispatch(loadingAction(false));
        throw err;
      });
  };
};

export const getTERMS_OF_USE = () => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return AuthService.getTERMS_OF_USE()
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({
          type: GET_TERMS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch(loadingAction(false));
        throw err;
      });
  };
};
export const getPrivatePolicy = () => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return AuthService.getPrivatePolicy()
      .then((response) => {
        dispatch(loadingAction(false));
        return dispatch({
          type: GET_PRIVATE_POLICY,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch(loadingAction(false));
        throw err;
      });
  };
};
