import React, { useRef } from "react";

import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import "./myData.css";
import download from "../../../Assets/images/download.png";
import mov_logos from "../../../Assets/images/dashboard/Mov_logos_png_11.png";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Cell,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  LabelList,
} from "recharts";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  getUserOutdoorAirQualityGraphAction,
  getUserIndoorAirQualityGraphAction,
  getUserAllergensGraphAction,
  getPafDataGraphAction,
  getPafDataGraphNewAction,
  getOverallAveragesAction,
  getPeakAirDataGraphAction,
  getInhelarWeeklyDataGraphAction,
  getUserDetailsGraphpageAction,
  getReliefInhalerGraphAction,
} from "../../../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { getItemLocalStorage } from "../../../Utils/Util";
// import ReactToPdf from "react-to-pdf";

import { BsInfoCircle } from "react-icons/bs";

import {
  Button,
  Grid,
  Popover,
  Typography,
  Popper,
  Box,
  Paper,
  Fade,
} from "@material-ui/core";

const ref = React.createRef();

const renderCustomizedLabel = (props) => {
  const { x, y, width, height, value } = props;

  const fireOffset = value.toString().length < 3;
  const offset = fireOffset ? 2 : 2;
  return (
    <text
      x={x + width - offset}
      y={y + height - 10}
      fill={fireOffset ? "#285A64" : "#fff"}
      textAnchor="end"
    >
      {value}
    </text>
  );
};

const MyData = () => {
  const dispatch = useDispatch();
  const [filterValue, setFilterValue] = useState("");
  var currentWeek = moment().startOf("isoWeek").format("YYYY-MM-DD");
  const [dateFilter, setDateFilter] = useState(currentWeek);
  const [todayDate, setTodayDate] = useState("");
  const [barOutdoorAirGraphLabels, setBarOutdoorAirGraphLabels] =
    useState("date");
  const [dateRange, setDateRange] = useState([]);
  const [barOutdoorAirGraphValues, setBarOutdoorAirGraphValues] = useState([]);
  const [barIndoorAirGraphLabels, setBarIndoorAirGraphLabels] = useState([]);
  const [barIndoorAirGraphValues, setBarIndoorAirGraphValues] = useState([]);
  const [barAllergensGraphLabels, setBarAllergensGraphLabels] = useState([]);
  const [barAllergensGraphValues, setBarAllergensGraphValues] = useState([]);
  const [barPafDataGraphLabels, setBarPafDataGraphLabels] = useState([]);
  const [barPafDataGraphValues, setBarPafDataGraphValues] = useState([]);
  const [bestBarPafDataGraphValues, setBestBarPafDataGraphValues] = useState(
    []
  );
  const [worstBarPafDataGraphValues, setWorstBarPafDataGraphValues] = useState(
    []
  );
  const [barPafDataGraphNewValues, setBarPafDataGraphNewValues] = useState([]);
  const [overallAverages, setOverallAverages] = useState({});
  const [outDoorAqiText, setOutDoorAqiText] = useState("");
  const [outDoorAqiTextColor, setOutDoorAqiTextColor] = useState("");
  const [inDoorAqiText, setInDoorAqiText] = useState("");
  const [inDoorAqiTextColor, setInDoorAqiTextColor] = useState("");
  const [riskLevelsTextColor, setRiskLevelsTextColor] = useState("");
  const [userDetailsGraphpage, setUserDetailsGraphpage] = useState({});
  const [convertAge, setConvertAge] = useState();
  const [reliefInhalerAverage, setReliefInhalerAverage] = useState("");
  const [barReliefInhalerColor, setBarReliefInhalerColor] = useState([]);
  const [barReliefInhalerValues, setBarReliefInhalerValues] = useState([]);
  const [barPeakAirDataGraphLabels, setBarPeakAirDataGraphLabels] = useState(
    []
  );
  const [barInhalerWeeklyDataGraphLabels, setBarInhalerWeeklyDataGraphLabels] =
    useState([]);
  const [screenCapture, setScreenCapture] = useState("");

  const displayData = useSelector(
    (state) => state.authReducer.userDataDisplay?.data?.data
  );

  const [permission, setPermission] = useState();

  const [avgInhalerUsesPerWeekColor, setAvgInhalerUsesPerWeekColor] =
    useState("");
  const [pafAverageTextColor, setPafAverageTextColor] = useState("");

  const [show, setShow] = useState(false);
  useEffect(() => {
    document.title = 'MOV || MY DATA'
  }, [])
  useEffect(() => {
    if (displayData) {
      let dataStr = JSON.parse(displayData.permissions);

      let dataDown = dataStr[34];

      setPermission(dataDown);
    }
  }, [displayData]);
  const barPafDataGraphChartData = {
    labels: barPafDataGraphLabels,
    datasets: [
      {
        data: barPafDataGraphValues,
        backgroundColor: ["rgb(0,255,127)", "rgb(0,255,127)", "rgb(255,0,0)"],
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    getUserOutdoorAirQualityGraph();
    getUserIndoorAirQualityGraph();
    getUserAllergensGraph();
  }, [filterValue, dateFilter]);
  useEffect(() => {
    getPafDataGraph();
    getPafDataGraphNew();
    getReliefInhalerGraph();
  }, [filterValue, dateFilter]);

  useEffect(() => {
    getOverallAverages();
    getUserDetailsGraphpage();
  }, []);

  useEffect(() => {
    getPeakAirDataGraph();
    getInhelarWeeklyDataGraph();
  }, []);
  const getPeakAirDataGraph = async () => {
    try {
      const response = await dispatch(
        getPeakAirDataGraphAction({
          userId: getItemLocalStorage("userId"),
        })
      );

      const userPeakAirDataGraphSuccess = response?.payload;
      if (userPeakAirDataGraphSuccess) {
        if (userPeakAirDataGraphSuccess?.status == 200) {
          setBarPeakAirDataGraphLabels(userPeakAirDataGraphSuccess?.data?.data);
        } else {
          swal({
            title: "Error!",
            text: userPeakAirDataGraphSuccess?.data?.message,
            buttons: false,
            icon: "error",
            timer: 2000,
          });
        }
      }
    } catch (err) {
      swal({
        title: "Error",
        text: err?.message,
        buttons: false,
        icon: "error",
        timer: 2000,
      });
    }
  };
  const getInhelarWeeklyDataGraph = async () => {
    try {
      const response = await dispatch(
        getInhelarWeeklyDataGraphAction({
          userId: getItemLocalStorage("userId"),
        })
      );

      const userInhelarWeeklyDataGraphSuccess = response?.payload;
      if (userInhelarWeeklyDataGraphSuccess) {
        if (userInhelarWeeklyDataGraphSuccess?.status == 200) {
          setBarInhalerWeeklyDataGraphLabels(
            userInhelarWeeklyDataGraphSuccess?.data?.data
          );
        } else {
          swal({
            title: "Error!",
            text: userInhelarWeeklyDataGraphSuccess?.data?.message,
            buttons: false,
            icon: "error",
            timer: 2000,
          });
        }
      }
    } catch (err) {
      swal({
        title: "Error",
        text: err?.message,
        buttons: false,
        icon: "error",
        timer: 2000,
      });
    }
  };

  const getUserDetailsGraphpage = async () => {
    try {
      const response = await dispatch(
        getUserDetailsGraphpageAction({ userId: getItemLocalStorage("userId") })
      );
      const userDetailsGraphpageSuccess = response?.payload;
      if (userDetailsGraphpageSuccess) {
        if (userDetailsGraphpageSuccess?.status == 200) {
          var from = userDetailsGraphpageSuccess?.data?.data?.dob.split("/");

          var birthdateTimeStamp = new Date(from[2], from[1] - 1, from[0]);

          var cur = new Date();
          var diff = cur - birthdateTimeStamp;
          var currentAge = Math.floor(diff / 31557600000);
          setConvertAge(currentAge);

          setUserDetailsGraphpage(userDetailsGraphpageSuccess?.data?.data);
        } else {
          swal({
            title: "Error!",
            text: userDetailsGraphpageSuccess?.data?.message,
            buttons: false,
            icon: "error",
            timer: 2000,
          });
        }
      }
    } catch (err) {
      swal({
        title: "Error",
        text: err?.message,
        buttons: false,
        icon: "error",
        timer: 2000,
      });
    }
  };

  const getUserOutdoorAirQualityGraph = async () => {
    try {
      const response = await dispatch(
        getUserOutdoorAirQualityGraphAction({
          userId: getItemLocalStorage("userId"),
          filter: filterValue,
          date: dateFilter,
        })
      );
      const userOutdoorAirQualityGraphSuccess = response?.payload;
      if (userOutdoorAirQualityGraphSuccess) {
        if (userOutdoorAirQualityGraphSuccess?.status == 200) {
          let graphData = userOutdoorAirQualityGraphSuccess?.data?.data;
          graphData.map((item, key) => {
            let a = graphData[key].week
              ? graphData[key].week.substring(0, 10)
              : "";
            let b = graphData[key].week
              ? graphData[key].week.substring(12, 23)
              : "";
            let fromDate = moment(a, "YYY-MM-DD");
            let fromMonth = fromDate.format("MMMM").substring(0, 3);
            let fromExactDate = fromDate.format("DD");
            // console.log("dates-----", fromExactDate);

            let toDate = moment(b, "YYY-MM-DD");
            let toMonth = toDate.format("MMMM").substring(0, 3);
            let toExactDate = toDate.format("DD");

            let fromToDateMonth =
              fromMonth +
              " " +
              fromExactDate +
              " - " +
              toMonth +
              " " +
              toExactDate;

            graphData[key].week = fromToDateMonth;
          });
          if (filterValue == "" || filterValue == "LAST_WEEK") {
            graphData.map((item, key) => {
              graphData[key].date = moment(item.date).format("dd");
            });
          }
          setBarOutdoorAirGraphValues(
            userOutdoorAirQualityGraphSuccess?.data?.data
          );
        } else {
          swal({
            title: "Error!",
            text: userOutdoorAirQualityGraphSuccess?.data?.message,
            buttons: false,
            icon: "error",
            timer: 2000,
          });
        }
      }
    } catch (err) {
      swal({
        title: "Error",
        text: err?.message,
        buttons: false,
        icon: "error",
        timer: 2000,
      });
    }
  };

  const getUserIndoorAirQualityGraph = async () => {
    try {
      const response = await dispatch(
        getUserIndoorAirQualityGraphAction({
          userId: getItemLocalStorage("userId"),
          filter: filterValue,
          date: dateFilter,
        })
      );
      const userIndoorAirQualityGraphSuccess = response?.payload;
      if (userIndoorAirQualityGraphSuccess) {
        if (userIndoorAirQualityGraphSuccess?.status == 200) {
          let graphData = userIndoorAirQualityGraphSuccess?.data?.data;
          graphData.map((item, key) => {
            let a = graphData[key].week
              ? graphData[key].week.substring(0, 10)
              : "";
            let b = graphData[key].week
              ? graphData[key].week.substring(12, 23)
              : "";
            let fromDate = moment(a, "YYY-MM-DD");
            let fromMonth = fromDate.format("MMMM").substring(0, 3);
            let fromExactDate = fromDate.format("DD");
            // console.log("dates-----", fromExactDate);

            let toDate = moment(b, "YYY-MM-DD");
            let toMonth = toDate.format("MMMM").substring(0, 3);
            let toExactDate = toDate.format("DD");

            let fromToDateMonth =
              fromMonth +
              " " +
              fromExactDate +
              " - " +
              toMonth +
              " " +
              toExactDate;

            graphData[key].week = fromToDateMonth;
          });
          if (filterValue == "" || filterValue == "LAST_WEEK") {
            graphData.map((item, key) => {
              graphData[key].date = moment(item.date).format("dd");
            });
          }
          setBarIndoorAirGraphValues(
            userIndoorAirQualityGraphSuccess?.data?.data
          );
        } else {
          swal({
            title: "Error!",
            text: userIndoorAirQualityGraphSuccess?.data?.message,
            buttons: false,
            icon: "error",
            timer: 2000,
          });
        }
      }
    } catch (err) {
      swal({
        title: "Error",
        text: err?.message,
        buttons: false,
        icon: "error",
        timer: 2000,
      });
    }
  };

  const getUserAllergensGraph = async () => {
    try {
      const response = await dispatch(
        getUserAllergensGraphAction({
          userId: getItemLocalStorage("userId"),
          filter: filterValue,
          date: dateFilter,
        })
      );
      const userAllergensGraphSuccess = response?.payload;
      if (userAllergensGraphSuccess) {
        if (userAllergensGraphSuccess?.status == 200) {
          let graphData = userAllergensGraphSuccess?.data?.data;
          graphData.map((item, key) => {
            let a = graphData[key].week
              ? graphData[key].week.substring(0, 10)
              : "";
            let b = graphData[key].week
              ? graphData[key].week.substring(12, 23)
              : "";
            let fromDate = moment(a, "YYY-MM-DD");
            let fromMonth = fromDate.format("MMMM").substring(0, 3);
            let fromExactDate = fromDate.format("DD");
            // console.log("dates-----", fromExactDate);

            let toDate = moment(b, "YYY-MM-DD");
            let toMonth = toDate.format("MMMM").substring(0, 3);
            let toExactDate = toDate.format("DD");

            let fromToDateMonth =
              fromMonth +
              " " +
              fromExactDate +
              " - " +
              toMonth +
              " " +
              toExactDate;

            graphData[key].week = fromToDateMonth;
          });
          if (filterValue == "" || filterValue == "LAST_WEEK") {
            graphData.map((item, key) => {
              graphData[key].date = moment(item.date).format("dd");
            });
          }
          setBarAllergensGraphValues(userAllergensGraphSuccess?.data?.data);
        } else {
          swal({
            title: "Error!",
            text: userAllergensGraphSuccess?.data?.message,
            buttons: false,
            icon: "error",
            timer: 2000,
          });
        }
      }
    } catch (err) {
      swal({
        title: "Error",
        text: err?.message,
        buttons: false,
        icon: "error",
        timer: 2000,
      });
    }
  };

  const getPafDataGraph = async () => {
    try {
      const response = await dispatch(
        getPafDataGraphAction({
          userId: getItemLocalStorage("userId"),
          filter: filterValue,
          date: dateFilter,
        })
      );
      const userPafDataGraphSuccess = response?.payload;
      if (userPafDataGraphSuccess) {
        if (userPafDataGraphSuccess?.status == 200) {
          setBarPafDataGraphLabels(
            userPafDataGraphSuccess?.data?.data?.map((i) => {
              return i.label;
            })
          );

          const DisplayData = userPafDataGraphSuccess?.data?.data;

          DisplayData.map((f, i) => {
            if (i === 1) {
              setBestBarPafDataGraphValues(f?.value);
            }
          });

          DisplayData.map((f, i) => {
            if (i === 2) {
              setWorstBarPafDataGraphValues(f?.value);
            }
          });

          DisplayData.map((f, i) => {
            if (i === 0) {
              let noDecimal = f?.value.toString().split(".");
              // console.log("no decimal", noDecimal[0]);
              // console.log("barPafDataGraphValues", barPafDataGraphValues);
              setBarPafDataGraphValues(noDecimal[0]);
            }
          });

          // setBarPafDataGraphValues(
          //   userPafDataGraphSuccess?.data?.data?.map((i) => {
          //     return i.value;
          //   })
          // );
        } else {
          swal({
            title: "Error!",
            text: userPafDataGraphSuccess?.data?.message,
            buttons: false,
            icon: "error",
            timer: 2000,
          });
        }
      }
    } catch (err) {
      swal({
        title: "Error",
        text: err?.message,
        buttons: false,
        icon: "error",
        timer: 2000,
      });
    }
  };
  const getPafDataGraphNew = async () => {
    try {
      const response = await dispatch(
        getPafDataGraphNewAction({
          userId: getItemLocalStorage("userId"),
          filter: filterValue,
          date: dateFilter,
        })
      );

      const userPafDataGraphNewSuccess = response?.payload;
      if (userPafDataGraphNewSuccess) {
        if (userPafDataGraphNewSuccess?.status == 200) {
          let graphData = userPafDataGraphNewSuccess?.data?.data;
          graphData.map((item, key) => {
            let a = graphData[key].week
              ? graphData[key].week.substring(0, 10)
              : "";
            let b = graphData[key].week
              ? graphData[key].week.substring(12, 23)
              : "";
            let fromDate = moment(a, "YYY-MM-DD");
            let fromMonth = fromDate.format("MMMM").substring(0, 3);
            let fromExactDate = fromDate.format("DD");
            // console.log("dates-----", fromExactDate);

            let toDate = moment(b, "YYY-MM-DD");
            let toMonth = toDate.format("MMMM").substring(0, 3);
            let toExactDate = toDate.format("DD");

            let fromToDateMonth =
              fromMonth +
              " " +
              fromExactDate +
              " - " +
              toMonth +
              " " +
              toExactDate;

            graphData[key].week = fromToDateMonth;
          });
          if (filterValue == "" || filterValue == "LAST_WEEK") {
            graphData.map((item, key) => {
              graphData[key].date = moment(item.date).format("dd");
            });
          }
          setBarPafDataGraphNewValues(userPafDataGraphNewSuccess?.data?.data);
        } else {
          swal({
            title: "Error!",
            text: userPafDataGraphNewSuccess?.data?.message,
            buttons: false,
            icon: "error",
            timer: 2000,
          });
        }
      }
    } catch (err) {
      swal({
        title: "Error",
        text: err?.message,
        buttons: false,
        icon: "error",
        timer: 2000,
      });
    }
  };
  const getReliefInhalerGraph = async () => {
    try {
      const response = await dispatch(
        getReliefInhalerGraphAction({
          userId: getItemLocalStorage("userId"),
          filter: filterValue,
          date: dateFilter,
        })
      );
      const userReliefInhalerGraphSuccess = response?.payload;
      if (userReliefInhalerGraphSuccess) {
        if (userReliefInhalerGraphSuccess?.status == 200) {
          let graphData = userReliefInhalerGraphSuccess?.data?.data;
          graphData.map((item, key) => {
            let a = graphData[key].week
              ? graphData[key].week.substring(0, 10)
              : "";
            let b = graphData[key].week
              ? graphData[key].week.substring(12, 23)
              : "";
            let fromDate = moment(a, "YYY-MM-DD");
            let fromMonth = fromDate.format("MMMM").substring(0, 3);
            let fromExactDate = fromDate.format("DD");
            // console.log("dates-----", fromExactDate);

            let toDate = moment(b, "YYY-MM-DD");
            let toMonth = toDate.format("MMMM").substring(0, 3);
            let toExactDate = toDate.format("DD");

            let fromToDateMonth =
              fromMonth +
              " " +
              fromExactDate +
              " - " +
              toMonth +
              " " +
              toExactDate;

            graphData[key].week = fromToDateMonth;
            // console.log("monthgraph--", graphData);
          });
          if (filterValue == "" || filterValue == "LAST_WEEK") {
            graphData.map((item, key) => {
              graphData[key].date = moment(item.date).format("dd");
            });
          }

          setBarReliefInhalerValues(userReliefInhalerGraphSuccess?.data?.data);

          let countAvg =
            userReliefInhalerGraphSuccess?.data?.countData?.countAvgUses;
          let sNumber = countAvg.toString();
          let result = sNumber.slice(0, 4);
          setReliefInhalerAverage(result);
        } else {
          swal({
            title: "Error!",
            text: userReliefInhalerGraphSuccess?.data?.message,
            buttons: false,
            icon: "error",
            timer: 2000,
          });
        }
      }
    } catch (err) {
      swal({
        title: "Error",
        text: err?.message,
        buttons: false,
        icon: "error",
        timer: 2000,
      });
    }
  };

  const getOverallAverages = async () => {
    try {
      const response = await dispatch(
        getOverallAveragesAction({
          userId: getItemLocalStorage("userId"),
        })
      );
      const userOverallAveragesSuccess = response?.payload;
      if (userOverallAveragesSuccess) {
        if (userOverallAveragesSuccess?.status == 200) {
          // console.log("da====", userOverallAveragesSuccess?.data?.data);
          setOverallAverages(userOverallAveragesSuccess?.data?.data);
          if (
            userOverallAveragesSuccess?.data?.data?.risk_Levels ===
            "No data found" || "NO"
          ) {
            setRiskLevelsTextColor("text-black");
          }
          if (userOverallAveragesSuccess?.data?.data?.risk_Levels === "POOR") {
            setRiskLevelsTextColor("text-red");
          }
          if (
            userOverallAveragesSuccess?.data?.data?.risk_Levels === "MODERATE"
          ) {
            setRiskLevelsTextColor("text-yellow");
          }
          if (userOverallAveragesSuccess?.data?.data?.risk_Levels === "GOOD") {
            setRiskLevelsTextColor("text-green");
          }
          if (
            Number(userOverallAveragesSuccess?.data?.data?.pm2outDoorAverage) <= 0
          ) {
            setOutDoorAqiText("No Data");
            setOutDoorAqiTextColor("text-black");
          }
          if (
            Number(userOverallAveragesSuccess?.data?.data?.pm2outDoorAverage) > 0 &&
            Number(userOverallAveragesSuccess?.data?.data?.pm2outDoorAverage) <= 50
          ) {
            setOutDoorAqiText("GOOD");
            setOutDoorAqiTextColor("text-green");
          }
          if (
            Number(userOverallAveragesSuccess?.data?.data?.pm2outDoorAverage) > 50 &&
            Number(userOverallAveragesSuccess?.data?.data?.pm2outDoorAverage) <= 100
          ) {
            setOutDoorAqiText("FAIR");
            setOutDoorAqiTextColor("text-yellow");
          }
          if (
             Number(userOverallAveragesSuccess?.data?.data?.pm2outDoorAverage) >=
            101 && Number(userOverallAveragesSuccess?.data?.data?.pm2outDoorAverage) >= 500
          ) {
            setOutDoorAqiText("POOR");
            setOutDoorAqiTextColor("text-red");
          }
          if (Number(userOverallAveragesSuccess?.data?.data?.pm2Average) <= 0) {
            // console.log('0')

            setInDoorAqiText("No Data");
            setInDoorAqiTextColor("text-black");
          }
          if (
            Number(userOverallAveragesSuccess?.data?.data?.pm2Average) >= 1 &&
            Number(userOverallAveragesSuccess?.data?.data?.pm2Average) <= 40
          ) {
            // console.log('1>40')
            setInDoorAqiText("GOOD");
            setInDoorAqiTextColor("text-green");
          }
          if (
            Number(userOverallAveragesSuccess?.data?.data?.pm2Average) > 40 &&
            Number(userOverallAveragesSuccess?.data?.data?.pm2Average) <= 75
          ) {
            // console.log('40>75')

            setInDoorAqiText("FAIR");
            setInDoorAqiTextColor("text-yellow");
          }
          if (
            Number(userOverallAveragesSuccess?.data?.data?.pm2Average) >75
          ) {
            // console.log('75?500')

            setInDoorAqiText("POOR");
            setInDoorAqiTextColor("text-red");
          }

          //
          if (userOverallAveragesSuccess?.data?.data.inhelarAverage <= 1) {
            setAvgInhalerUsesPerWeekColor("text-green");
          }
          if (
            userOverallAveragesSuccess?.data?.data.inhelarAverage > 1 &&
            userOverallAveragesSuccess?.data?.data.inhelarAverage <= 2
          ) {
            setAvgInhalerUsesPerWeekColor("text-yellow");
          }
          if (userOverallAveragesSuccess?.data?.data.inhelarAverage > 2) {
            setAvgInhalerUsesPerWeekColor("text-red");
          }

          if (userOverallAveragesSuccess?.data?.data.pafAverage >= 80) {
            setPafAverageTextColor("text-green");
          }
          if (
            userOverallAveragesSuccess?.data?.data.pafAverage > 60 &&
            userOverallAveragesSuccess?.data?.data.pafAverage < 80
          ) {
            setPafAverageTextColor("text-yellow");
          }
          if (userOverallAveragesSuccess?.data?.data.pafAverage < 60) {
            setPafAverageTextColor("text-red");
          }
        } else {
          swal({
            title: "Error!",
            text: userOverallAveragesSuccess?.data?.message,
            buttons: false,
            icon: "error",
            timer: 2000,
          });
        }
      }
    } catch (err) {
      swal({
        title: "Error",
        text: err?.message,
        buttons: false,
        icon: "error",
        timer: 2000,
      });
    }
  };

  const onSelect = (e) => {
    let currentDate = moment().utc().format("MMMM Do YYYY");

    let last6Month = moment().subtract(168, "days").format("MMMM Do YYYY -");
    // let currentWeek = moment().subtract(6, "days").format("MMMM Do YYYY -");
    let last3Week = moment().subtract(21, "days").format("MMMM Do YYYY -");
    let lastMonth = moment().subtract(28, "days").format("MMMM Do YYYY -");
    let last3Month = moment().subtract(90, "days").format("MMMM Do YYYY -");

    var currentWeek = moment().startOf("isoWeek").format("YYYY-MM-DD");

    var currentWeekFormat = moment()
      .startOf("isoWeek")
      .format("MMMM Do YYYY -");

    var currentWeekEnd = moment(currentWeek)
      .add(6, "days")
      .format("MMMM Do YYYY");

    var lastWeek = moment().startOf("Week").isoWeekday(1).format("YYYY-MM-DD");
    var lastWeekFormat = moment()
      .startOf("Week")
      .isoWeekday(1)
      .format("MMMM Do YYYY -");

    var lastWeekEnd = moment(lastWeek).add(6, "days").format("MMMM Do YYYY");

    if (e.target.value == "" || e.target.value == "LAST_WEEK") {
      setBarOutdoorAirGraphLabels("date");
    }
    if (
      e.target.value == "LAST_3WEEK" ||
      e.target.value == "LAST_MONTH" ||
      e.target.value == "LAST_3MONTH" ||
      e.target.value == "LAST_6MONTH"
    ) {
      setBarOutdoorAirGraphLabels("week");
      setTodayDate(currentDate);
    }
    if (e.target.value == "") {
      setDateRange(currentWeek);
      setDateFilter(currentWeek);
      setTodayDate(currentWeekEnd);
      setDateRange(currentWeekFormat);
    }
    if (e.target.value == "LAST_WEEK") {
      setDateRange(lastWeek);
      setDateFilter(lastWeek);
      setTodayDate(lastWeekEnd);
      setDateRange(lastWeekFormat);
    }
    if (e.target.value == "LAST_3WEEK") {
      setDateRange(last3Week);
    }
    if (e.target.value == "LAST_MONTH") {
      setDateRange(lastMonth);
    }
    if (e.target.value == "LAST_3MONTH") {
      setDateRange(last3Month);
    }
    if (e.target.value == "LAST_6MONTH") {
      setDateRange(last6Month);
    }
    setFilterValue(e.target.value);
    setShow(true);
  };

  const renderInhalerGraph = () => {
    return barReliefInhalerValues.map((entry, index) => {
      let colorCode = "grey";
      // if (entry.avgUses <= 1) {
      //   colorCode = "#68F16C";
      // }
      // if (entry.avgUses > 1 && entry.avgUses <= 2) {
      //   colorCode = "#F1F576";
      // }
      // if (entry.avgUses > 2) {
      //   colorCode = "#FF4747";
      // }
      return (
        <>
          <Cell key={`cell-${index}`} fill={colorCode} />
        </>
      );
    });
  };
  const renderPafGraph = () => {
    return barPafDataGraphNewValues.map((entry, index) => {
      // let colorCode = entry.averageCountPafColour === "GREEN" ? '#68f16c' : entry.averageCountPafColour;
      let colorCode = entry.averageCountPafColour ==="GREEN" ? '#68f16c' : entry.averageCountPafColour === 'YELLOW' ? '#fef8a0' : entry.averageCountPafColour === 'RED' ? '#e61610' : '#a2a2a2';

      // if (entry.averageCount <= 60) {
      //   colorCode = "#FF4747";
      // }
      // if (entry.averageCount > 60 && entry.averageCount <= 80) {
      //   colorCode = "#F1F576";
      // }
      // if (entry.averageCount > 80) {
      //   colorCode = "#68F16C";
      // }
      return (
        <>
          <Cell key={`cell-${index}`} fill={colorCode} />
        </>
      );
    });
  };
  const container = React.useRef(null);
  // const container = React.useRef();
  const pdfExportComponent = React.useRef(null);

  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElTwo, setAnchorElTwo] = React.useState(null);

  const handleClick = (event) => {

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClickTwo = (event) => {
    setAnchorElTwo(event.currentTarget);
  };

  const handleCloseTwo = () => {
    setAnchorElTwo(null);
  };

  const openTwo = Boolean(anchorElTwo);
  const idTwo = openTwo ? "simple-popover" : undefined;

  const CM = userDetailsGraphpage?.height;

  // var feet = (0.0328084 * CM);
  // var inches = (0.0328 * CM)
  //     inches=inches.toFixed(2);
  //     feet=feet.toFixed(2);
  // var last = String(feet).slice(0, 3);  
  // let result = last.replace(".", "' ");
  // let resultTwo = "Height: " + feet + "\'" + inches + "\"";
  let feet = CM * 0.0328084
  let feetShow = (Math.floor(feet))
  let feetRest = (((feet * 100) % 100) / 100)
  let inches = (Math.round(feetRest * 12))
  let resultTwo = "Height: " + (feetShow) + "\'" + (inches) + "\""

  return (
    <>
      <PDFExport
        ref={pdfExportComponent}
        paperSize="auto"
        margin={60}
        fileName={`Report for ${new Date().getFullYear()}`}
        author="KendoReact Team"
      >
        <div ref={container} id="divToPrint" className="mydata">
          <div className="mydata_title">My Data Summary</div>
          <div className="mydata_title_contant">
            Here you can find an easy to read summary of your Asthma-related
            health trends. You can view your 'All-Time' summary or scroll down
            to select a date range to view a more detailed summary of your
            progress. Remember, you own all of your data. If you want to
            download a copy to share with your provider or loved ones, or just
            for your own records, click the 'Download' Icon and a PDF of the
            data for the selected date range will be created.
          </div>
          <div className="mydata_view_body">
            <div className="mydata_view_body_top">Overall Averages</div>
            <div className="mydata_view_body_bottom">
              <div className="mydata_view_body_bottom1">
                <div className="mydata_TOP">
                  Average Relief Inhaler Uses Per Week
                </div>
                <div className={avgInhalerUsesPerWeekColor}>
                  {overallAverages.inhelarAverage}
                </div>
              </div>
              <div className="mydata_view_body_bottom2">
                <div className="mydata_TOP">Average PAF</div>
                <div className={overallAverages.pafAverageColour === 'GREEN' ? 'text-green' : overallAverages.pafAverageColour === 'YELLOW' ? 'text-yellow' : overallAverages.pafAverageColour === 'RED' ? 'text-red' : 'text-black'} >
                  {overallAverages.pafAverage + " L/m"}
                </div>
              </div>
              <div className="mydata_view_body_bottom3">
                <div className="mydata_TOP">Average Outdoor AQI</div>
                <div className={outDoorAqiTextColor}>{outDoorAqiText}</div>
              </div>
              <div className="mydata_view_body_bottom4">
                <div className="mydata_TOP">Average Indoor AQI</div>
                <div className={inDoorAqiTextColor}>{inDoorAqiText}</div>
              </div>
              <div className="mydata_view_body_bottom5">
                <div className="mydata_TOP">Average Allergen Levels</div>
                <div className={riskLevelsTextColor}>
                  {overallAverages.risk_Levels
                    ? overallAverages.risk_Levels === 'NO'
                      ? 'No Data'
                      : overallAverages?.risk_Levels === 'MODERATE' ? 'FAIR': overallAverages?.risk_Levels
                    : 'No Data'
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="mydata_vartical_graph ">
            <div className="mydata_vartical_graph1 ">
              <div className="mydata_vartical_graph1_top">
                Peak Air Flow Trends
              </div>
              <div className="mydata_vartical_graph1_bottom">
                <div
                  className="mydata_vartical_graph1_bottom_left"
                  style={{ marginTop: "35px", marginRight: "10px" }}
                >
                  <div className="mydata_good">Good</div>
                  <div className="mydata_morderate">Moderate</div>
                  <div className="mydata_poor">Poor</div>
                </div>
                <div class="vl"></div>
                <div className="mydata_vartical_graph1_bottom_right">
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      data={barPeakAirDataGraphLabels}
                      margin={{
                        top: 20,
                        right: 0,
                        bottom: 20,
                        left: 0,
                      }}
                      layout="vertical"
                    >
                      <XAxis
                        type="number"
                        interval={0}
                        tick={false}
                        tickLine={false}
                        axisLine={false}
                        style={{ fill: "white", display: "none" }}
                      />

                      <Bar
                        dataKey="count"
                        fill="#E2E8EF"
                        padding={{ left: 10 }}
                      >
                        <LabelList
                          dataKey="count"
                          content={renderCustomizedLabel}
                        />
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
            <div className="mydata_vartical_graph2">
              <div className="mydata_vartical_graph1_top">
                Relief Inhaler Use Trends
              </div>

              <div className="mydata_vartical_graph2_bottom">
                <div
                  className="mydata_vartical_graph1_bottom_left"
                  style={{ marginTop: "35px", marginRight: "20px" }}
                >
                  <div
                    className="mydata_good"
                    style={{
                      marginLeft:'5px',
                      fontSize:'16px',
                      color: "#a2a2a2",
                    }}
                  >
                    {'>2x Per Week'}
                  </div>
                  <div
                    className="mydata_morderate"
                    style={{
                      marginLeft:'5px',
                      fontSize:'16px',
                      color: "gray",
                    }}
                  >
                    1-2x Per Week
                  </div>
                  <div
                    className="mydata_poor"
                    style={{
                      marginLeft:'5px',
                      fontSize:'16px',
                      color: "gray",
                    }}
                  >
                    Weeks with No Uses
                  </div>
                </div>
                <div class="vl"></div>
                <div className="mydata_vartical_graph1_bottom_right">
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      data={barInhalerWeeklyDataGraphLabels}
                      margin={{
                        top: 20,
                        right: 0,
                        bottom: 20,
                        left: 0,
                      }}
                      layout="vertical"
                    >
                      <XAxis
                        type="number"
                        interval={0}
                        tick={false}
                        tickLine={false}
                        axisLine={false}
                        style={{ fill: "white", display: "none" }}
                      />

                      <Bar dataKey="count" fill="#E2E8EF">
                        <LabelList
                          dataKey="count"
                          content={renderCustomizedLabel}
                        />
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
          <div className="mydata_table">
            <div className="mydata_table_top">
              <div className="mydata_table_top_title">
                <form action="/action_page.php">
                  <select
                    style={{ marginTop: "10px" }}
                    name="time-range"
                    id="time-range"
                    onChange={onSelect}
                    defaultValue={""}
                  >
                    <option value="" disabled selected>
                      Select a Date Range
                    </option>
                    <option value="">Current Week</option>
                    <option value="LAST_WEEK">Last Week</option>
                    <option value="LAST_3WEEK">Last 3 Weeks</option>
                    <option value="LAST_MONTH">Last Month</option>
                    <option value="LAST_3MONTH">Last 3 Months</option>
                    <option value="LAST_6MONTH">Last 6 Months</option>
                  </select>
                </form>
              </div>
              <div className="mydata_table_top_logo_body">
                {show ? (
                  <div
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={exportPDFWithComponent}
                  >
                    <img src={download} className="mydata_table_top_logo" />

                    <div className="mydata_table_top_logo_cont">PDF</div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="mydata_table_head">
              {dateRange} {todayDate}
            </div>
            <div className="mydata_table1">
              <div className="mydata_table1_left">
                <div className="view_chart_middle_mydata">
                  <div className="view_chart_middle_top_mydata">
                    <div className="view_chart_Title"></div>
                    Relief Inhaler Use
                    <div className="view_chart_icon">
                      <Typography
                        aria-describedby={id}
                        variant="contained"
                        onClick={handleClick}
                      >
                        <BsInfoCircle style={{ color: "#a2a2a2" }} />
                      </Typography>
                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <Typography sx={{ p: 2 }} className="view_char_desc">
                          Average = the average number of Relief Inhaler Uses
                          for days that a use is recorded. This does not count
                          days where the inhaler use was not recorded.
                        </Typography>
                      </Popover>
                    </div>
                  </div>
                  <div className="view_chart_middle_body">
                    <div className=" view_chart_middle_body_left_mydata">
                      <ResponsiveContainer height="100%" width="100%">
                        <BarChart
                          data={barReliefInhalerValues}
                          margin={{
                            top: 0,
                            right: 0,
                            left: 15,
                            bottom: 50,
                          }}
                        >
                          <XAxis
                            dataKey={barOutdoorAirGraphLabels}
                            style={{
                              fontSize: "0.75rem",
                              fill: "#bfbfbf",
                            }}
                            textAnchor="end"
                            sclaeToFit="true"
                            verticalAnchor="start"
                            interval={0}
                            angle="-45"
                          />
                          <YAxis style={{ fill: "#bfbfbf" }} />
                          <Tooltip cursor={{ fill: "transparent" }} />

                          <Bar name="Uses" dataKey="avgUses">
                            {renderInhalerGraph()}
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                    <div className="mydata_view_chart_middle_body_right">
                      <div
                        className="view_chart_middle_body_right2"
                        style={{ marginTop: "130px" }}
                      >
                        <u>Average </u>
                        <br /> {reliefInhalerAverage} Uses/Day
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mydata_table1_right">
                <div className="view_chart_middle_mydata">
                  <div className="view_chart_middle_top_mydata">
                    <div className="view_chart_Title"></div>
                    Peak Air Flow (PAF)
                    <div className="view_chart_icon">
                      <Typography
                        aria-describedby={idTwo}
                        variant="contained"
                        onClick={handleClickTwo}
                      >
                        <BsInfoCircle style={{ color: "#a2a2a2" }} />
                      </Typography>
                      <Popover
                        id={idTwo}
                        open={openTwo}
                        anchorEl={anchorElTwo}
                        onClose={handleCloseTwo}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <Typography sx={{ p: 2 }} className="view_char_desc">
                          Best = Your best overall PAF reading to date.
                          <br />
                          Worst = Your worst PAF reading during the selected
                          time period.
                          <br />
                          Average = Your average PAF reading test during the
                          selected time period.
                        </Typography>
                      </Popover>
                    </div>
                  </div>
                  <div className="view_chart_middle_body">
                    <div className="view_chart_middle_body_left_mydata">
                      <ResponsiveContainer>
                        <BarChart
                          data={barPafDataGraphNewValues}
                          margin={{
                            top: 0,
                            right: 0,
                            left: 15,
                            bottom: 50,
                          }}
                        >
                          <XAxis
                            dataKey={barOutdoorAirGraphLabels}
                            style={{
                              fontSize: "0.75rem",
                              fill: "#bfbfbf",
                              fontFamily: "Montserrat",
                            }}
                            textAnchor="end"
                            sclaeToFit="true"
                            verticalAnchor="start"
                            interval={0}
                            angle="-45"
                          />
                          <YAxis style={{ fill: "#bfbfbf" }} />
                          <Tooltip cursor={{ fill: "transparent" }} />

                          <Bar dataKey="averageCount" name="PAF">
                            {renderPafGraph()}
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                    <div className="mydata_view_chart_middle_body_right">
                      <div
                        className="view_chart_middle_body_right1"
                        style={{ marginTop: "60px" }}
                      >
                        <u>Best</u>
                        <br />
                        {bestBarPafDataGraphValues} L/m
                      </div>
                      <div
                        className="view_chart_middle_body_right2"
                        style={{ marginTop: "20px" }}
                      >
                        <u>Worst </u>
                        <br />
                        {worstBarPafDataGraphValues} L/m
                      </div>
                      <div
                        className="view_chart_middle_body_right3"
                        style={{ marginTop: "20px" }}
                      >
                        <u>Average</u>
                        <br />
                        {barPafDataGraphValues} L/m
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mydata_table2">
              <div className="mydata_table2_header">Outdoor Air Quality</div>
              <div className="mydata_table2_body">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart
                    data={barOutdoorAirGraphValues}
                    margin={{
                      top: 0,
                      right: 0,
                      left: 5,
                      bottom: 50,
                    }}
                  >
                    <XAxis
                      dataKey={barOutdoorAirGraphLabels}
                      type="category"
                      style={{
                        fontSize: "0.75rem",
                        fill: "#bfbfbf",
                      }}
                      padding={{ left: 30, right: 10 }}
                      textAnchor="end"
                      sclaeToFit="true"
                      verticalAnchor="start"
                      interval={0}
                      angle="-45"
                    />
                    <YAxis style={{ fill: "#bfbfbf" }} />
                    <Tooltip />
                    <Legend
                      layout="vetical"
                      // verticalAlign="middle"
                      verticalAlign="top"
                      align="right"
                      iconType="rect"
                      wrapperStyle={{
                        marginTop: "50px",
                        marginRight: "-180px",
                      }}
                    />
                    <Line
                      dataKey="avgDust"
                      name="PM2.5"
                      stroke="#1E90FF"
                      dot={false}
                      activeDot={{ r: 8 }}
                    />
                    {/* <Line
                      dataKey="avgPressure"
                      name="Pressure"
                      stroke="#1EFFD9"
                      dot={false}
                      activeDot={{ r: 8 }}
                    /> */}
                    {/* <Line
                      name="Temp"
                      dataKey="avgTemp"
                      stroke="#BCFF1E"
                      dot={false}
                      activeDot={{ r: 8 }}
                    /> */}
                    {/* <Line
                      name="Humidity"
                      dataKey="avgMoldRisk"
                      stroke="#FF1EDA"
                      dot={false}
                      activeDot={{ r: 8 }}
                    /> */}
                    {/* <Line
                      name="CO2"
                      dataKey="avgCo2"
                      stroke="#781EFF"
                      dot={false}
                      activeDot={{ r: 8 }}
                    /> */}
                    <Line
                      name="PM10"
                      dataKey="avgPm10"
                      stroke="#FF871E"
                      dot={false}
                      activeDot={{ r: 8 }}
                    />
                    <Line
                      name="Ozone"
                      dataKey="avgOzone"
                      stroke="#0FC89C"
                      dot={false}
                      activeDot={{ r: 8 }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
              <div className="view_chart_bottom_content">
                This graph displays the most prevalent Outdoor Air Quality
                measures over the selected timeframe.
              </div>
            </div>
            <div className="mydata_table2">
              <div className="mydata_table2_header">Indoor Air Quality</div>
              <div className="mydata_table2_body">
                <ResponsiveContainer>
                  <LineChart
                    data={barIndoorAirGraphValues}
                    margin={{
                      top: 0,
                      right: 0,
                      left: 15,
                      bottom: 50,
                    }}
                  >
                    <XAxis
                      dataKey={barOutdoorAirGraphLabels}
                      type="category"
                      style={{
                        fontSize: "0.75rem",
                        fill: "#bfbfbf",
                      }}
                      padding={{ left: 30, right: 10 }}
                      textAnchor="end"
                      sclaeToFit="true"
                      verticalAnchor="start"
                      interval={0}
                      angle="-45"
                    />
                    <YAxis style={{ fill: "#bfbfbf" }} />
                    <Tooltip />
                    <Legend
                      layout="vetical"
                      verticalAlign="top"
                      align="right"
                      iconType="rect"
                      wrapperStyle={{
                        marginTop: "90px",
                        marginRight: "-180px",
                      }}
                    />
                    <Line
                      dataKey="avgDust"
                      name="PM2.5"
                      stroke="#1E90FF"
                      dot={false}
                      activeDot={{ r: 8 }}
                    />
                    {/* <Line
                      dataKey="avgPressure"
                      name="Pressure"
                      stroke="#1EFFD9"
                      dot={false}
                      activeDot={{ r: 8 }}
                    /> */}
                    <Line
                      name="Temp"
                      dataKey="avgTemp"
                      stroke="#BCFF1E"
                      dot={false}
                      activeDot={{ r: 8 }}
                    />
                    <Line
                      name="Humidity"
                      dataKey="avgMoldRisk"
                      stroke="#FF1EDA"
                      dot={false}
                      activeDot={{ r: 8 }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
              <div className="view_chart_bottom_content">
                This graph displays the Indoor Air Quality taken from your
                Indoor Air Quality Monitor over the selected timeframe.
              </div>
            </div>
            <div className="mydata_table2">
              <div className="mydata_table2_header">Allergens</div>
              <div className="mydata_table2_body">
                <ResponsiveContainer>
                  <LineChart
                    data={barAllergensGraphValues}
                    margin={{
                      top: 0,
                      right: 0,
                      left: 15,
                      bottom: 50,
                    }}
                  >
                    <XAxis
                      dataKey={barOutdoorAirGraphLabels}
                      type="category"
                      style={{
                        fontSize: "0.75rem",
                        fill: "#bfbfbf",
                      }}
                      padding={{ left: 30, right: 10 }}
                      textAnchor="end"
                      sclaeToFit="true"
                      verticalAnchor="start"
                      interval={0}
                      angle="-45"
                    />
                    <YAxis style={{ fill: "#bfbfbf" }} />
                    <Tooltip />
                    <Legend
                      layout="vetical"
                      verticalAlign="top"
                      align="right"
                      iconType="rect"
                      wrapperStyle={{
                        marginTop: "80px",
                        marginRight: "-180px",
                      }}
                    />

                    <Line
                      name="Trees"
                      dataKey="avgTreesPollen"
                      stroke="#1E90FF"
                      dot={false}
                      activeDot={{ r: 8 }}
                    />
                    <Line
                      dataKey="avgWeedPollen"
                      name="Weeds"
                      stroke="#1EFFD9"
                      dot={false}
                      activeDot={{ r: 8 }}
                    />
                    <Line
                      dataKey="avgGrassPollen"
                      name="Grass"
                      stroke="#BCFF1E"
                      dot={false}
                      activeDot={{ r: 8 }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
              <div className="view_chart_bottom_content">
                This graph displays the most prevalent Outdoor Allergens over
                the selected timeframe.
              </div>
            </div>
            {/* </div> */}
            <div className="bio">
              <div className="bio_title">Biometrics</div>
              <div className="bio_body">
                <div className="bio_body_content">{resultTwo}</div>
                <div className="bio_body_content">
                  {/* {"Weight: " + Math.round(userDetailsGraphpage?.weight * 2.2) + " lbs"} */}
                  {"Weight: " + Math.round(userDetailsGraphpage?.weight) + " lbs" || "Not available"}

                </div>
                <div className="bio_body_content">{"Age: " + convertAge}</div>
                <div className="bio_body_content">
                  {"Occupation: " + userDetailsGraphpage?.occupation}
                </div>
              </div>
            </div>
            <div className="mydata_table_footer">
              <div className="mydata_table_footer_left">
                This health summary is not intended to diagnose, treat or
                mitigate Asthma. It is only a reference to share with your
                healthcare provider if you so choose.
              </div>
              <div className="mydata_table_footer_right">
                <img
                  className="mydata_table_footer_right_img"
                  src={mov_logos}
                  alt="logo"
                />
              </div>
            </div>
          </div>
          {/* </ScreenCapture> */}
        </div>
      </PDFExport>
    </>
  );
};

export default MyData;
