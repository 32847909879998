import React from "react";
import "../Dashboard/home.css";
import "../Dashboard/style.css";
import {
  TabContainer,
  TabPane,
  TabContent,
  Row,
  Col,
  Nav,
  NavItem,
} from "react-bootstrap";
import { AiFillHome } from "@react-icons/all-files/ai/AiFillHome";
import { AiOutlineLineChart } from "@react-icons/all-files/ai/AiOutlineLineChart";
// import { BsFillBagCheckFill } from "@react-icons/all-files/bs/BsFillBagCheckFill";
import { BsFillBagCheckFill } from "react-icons/bs";
import { RiAccountCircleFill, RiFolderDownloadFill } from "react-icons/ri";
import { AiOutlineCopyright } from "react-icons/ai";
import { NavLink, Link } from "react-router-dom";

import copyright from "../../Assets/images/dashboard/copyright.png";
import { useState } from ".";
// const [currentYear , setcurrentYear] = useState();

const SideBar = () => {
  return (
    <>
      <Nav
        variant="pills"
        className="navItemCss"
        style={
          {
            // position:"relative"
          }
        }
      >
        <div>
          <NavItem style={{ marginTop: "20px" }}>
            <NavLink
              className="navcss"
              activeClassName="activecss"
              style={{ textDecoration: "inherit" }}
              to={"/home"}
            >
              <div className="d-flex hello">
                <AiFillHome
                  style={{
                    fontSize: "22px",
                  }}
                />
                <div className="navLinkTitle">Home</div>
              </div>
            </NavLink>
          </NavItem>
          <NavItem style={{ marginTop: "30px" }}>
            <NavLink
              className="navcss"
              activeClassName="activecss"
              style={{ textDecoration: "inherit" }}
              to={"/my-data"}
            >
              <div className="d-flex hello">
                <AiOutlineLineChart style={{ fontSize: "25px" }} />
                <div className="navLinkTitle"> My Data</div>
              </div>
            </NavLink>
          </NavItem>
          <NavItem style={{ marginTop: "30px" }}>
            <NavLink
              className="navcss"
              activeClassName="activecss"
              style={{ textDecoration: "inherit" }}
              to={"/my-asthamadata"}
            >
              <div className="d-flex hello">
                <BsFillBagCheckFill style={{ fontSize: "25px" }} />
                <div className="navLinkTitle">My Asthma</div>
              </div>
            </NavLink>
          </NavItem>
          <NavItem style={{ marginTop: "30px" }}>
            <NavLink
              className="navcss"
              activeClassName="activecss"
              style={{ textDecoration: "inherit" }}
              to={"/my-account"}
            >
              <div className="d-flex hello">
                <RiAccountCircleFill style={{ fontSize: "25px" }} />
                <div className="navLinkTitle"> Account</div>
              </div>
            </NavLink>
          </NavItem>
          <NavItem style={{ marginTop: "30px" }}>
            <a
              className="navcss"
              activeClassName="activecss"
              style={{ textDecoration: "inherit" }}
              href={"https://www.dropbox.com/s/8soi89z57vsvpqa/MOV%20Asthma%20Kit%20-%20Owners%20Manual%202022.pdf?dl=1"}
            >
              <div className="d-flex hello">
                <RiFolderDownloadFill style={{ fontSize: "25px" }} />
                <div className="navLinkTitle"> Owner’s Manual</div>
              </div>
            </a>
          </NavItem>
        </div>

        <div className="nav_bottom_contactUs">
          {/* <span style={{ marginRight: "6px", fontSize: "15px" }}>
            <AiOutlineCopyright />
          </span> */}
          <span style={{ padding:'0 5px',marginBottom:'5px', fontSize: "18px" }}>Contact Us</span><br />
          <p style={{ padding:'0 5px', marginBottom:'5px',fontSize: "14px" }}>Need Help? Reach us at</p> 
          <p style={{ padding:'0 5px', marginTop:'0'  }}>
            <b className="text-primary">membersupport@movhealth.com</b>
            </p>
        </div>

        <div className="nav_bottom">
          <span style={{ marginRight: "6px", fontSize: "15px" }}>
            <AiOutlineCopyright />
          </span>
          <span style={{ marginTop: "0px" }}>2022 MOV Health</span>
        </div>
      </Nav>
    </>
  );
};

export default SideBar;
